<template>
  <div class="signup-banner">
    <div class="signup-banner__title">
      {{ $t('product.viewProductLabel', { product: product.name }) }}
    </div>
    <v-btn
      rounded
      color="#white"
      x-large
      class="signup-banner__button"
      :href="`/pdf/label-${product.id}.pdf`"
    >
      {{ $t('product.viewLabel') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ProductLabel',

  props: { product: { type: Object, required: true } }
}
</script>

<style lang="scss" scoped>
.signup-banner {
  padding: 140px 100px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f75007;

  &__title {
    font-size: 36px;
    padding: 0 40px;
  }

  &__button {
    color: #f75007;
    font-weight: 600;
  }
}

@media only screen and (max-width: 768px) {
  .signup-banner {
    flex-direction: column;
    padding: 90px 0;
    text-align: center;
    line-height: 1.2;

    &__title {
      margin-bottom: 25px;
      font-size: 42px;
    }
  }
}
</style>
