<template>
  <div class="opportunity">
    <div class="opportunity__item opportunity__item--bordered">
      <div class="opportunity__title">
        {{ $t('potential.businessOpportunity') }}
      </div>

      <p>
        {{ $t('potential.opportunity') }}
      </p>
    </div>

    <div class="opportunity__item">
      <div class="opportunity__photo">
        <img src="@/assets/images/potential/opportunity.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Opportunity'
}
</script>

<style lang="scss" scoped>
.opportunity {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  color: #555555;
  align-items: center;
  font-size: 18px;

  &__title {
    font-size: 44px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__item {
    text-align: justify;
  }

  &__item {
    margin-top: 40px;
  }

  &__item--bordered {
    border: 4px solid #f75007;
    padding: 20px 40px;
  }

  &__photo {
    img {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .opportunity {
    flex-direction: row;
    justify-content: center;

    &__item {
      max-width: 500px;
      min-width: 500px;
      min-height: 400px;
      max-height: 400px;
    }

    &__photo {
      max-width: 400px;
    }

    &__item--bordered {
      margin-right: 50px;
    }
  }
}

@media only screen and (min-width: 1301px) {
  .opportunity {
    flex-direction: row;
    justify-content: center;

    &__item {
      max-width: 500px;
      min-width: 500px;
      min-height: 500px;
      max-height: 500px;
    }

    &__item--bordered {
      margin-right: 60px;
    }
  }
}
</style>
