<template>
  <div class="cart-body">
    <div class="cart-body__item">
      <div class="cart-body__item__image">
        <img :src="item.product.img" />
      </div>
      <div class="cart-body__item__description">
        <div class="cart-body__item__description__title">
          {{ item.quantity }}x
          {{ item.product.name }}
        </div>
        <div class="cart-body__item__description__code">
          {{ item.product.code }}
        </div>
      </div>
      <div class="cart-body__item__price">
        <div class="cart-body__item__price__summary">
          <div class="cart-body__item__price__summary__item">
            <strong>{{ $t("cart.price") }}:</strong> ${{
              formatNumber(item.product.price, 2)
            }}
          </div>
          <div class="cart-body__item__price__summary__item">
            <strong>{{ $t("checkout.tax") }}:</strong> {{ itemTax }}%
          </div>
          <div class="cart-body__item__price__summary__item">
            <strong>{{ $t("cart.totalPrice") }}:</strong> ${{
              formatNumber(itemPrice, 2)
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formattingMixin } from "@/mixins/formatting.js";

export default {
  name: "ConfirmOrderItem",

  mixins: [formattingMixin],

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    itemPrice() {
      return (this.item.product.price + this.itemTax) * this.item.quantity;
    },

    itemTax() {
      return this.item.product.tax
        ? (this.item.product.tax / 100) * this.item.product.price
        : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
}

.cart-body {
  padding: 25px 0px;
  display: block;
  width: 100%;

  &__item {
    display: flex;
    width: 100%;
    justify-content: space-around;

    &__image {
      padding-top: 15px;
      img {
        width: 40px;
      }
    }

    &__description {
      padding: 15px;
      text-align: left;

      &__title {
        font-size: 18px;
        font-weight: 700;
      }

      &__code {
        color: #999;
      }
    }

    &__price {
      padding-left: 20px;

      &__summary {
        text-align: right;

        &__item {
          white-space: nowrap;
        }
      }
    }
  }
}

.button-huge {
  height: 50px !important;
  font-size: 24px !important;
}

@media only screen and (min-width: 900px) {
  .cart-body {
    // display: none;
  }

  .table-cart-body {
    display: block;
  }
}
</style>
