<template lang="en">
	<div class="testimonials">
		<div class="testimonials__heading-box">
			{{$t('homepage.testimonials')}}							
		</div>
		<div class="testimonials__quotes-box" >
			<div class="testimonials__quotes-box__quote" v-for='testimonial in testimonials' :key="testimonial.id">
				<span><!--Icon by Icons8 Line Awesome (https://icons8.com/line-awesome)-->
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="quote-right" viewBox="0 0 512 545.5"> <path 	d="M64 160h160v160c0 52.826-43.174 96-96 96v-32c35.526 0 64-28.474 64-64H64V160zm224 0h160v160c0 52.826-43.174 96-96 96v-32c35.526 0 64-28.474 64-64H288V160zM96 192v96h96v-96H96zm224 0v96h96v-96h-96z" ></path>
					</svg>
				</span>
				<p class="testimonials__quotes-box__quote__text">{{testimonial.text}}</p>
				<img :src="testimonial.img" :alt=testimonial.name />

				<div class="">
					<h5 class="testimonials__quotes-box__quote__name">{{testimonial.name}}</h5>
					<p class="testimonials__quotes-box__quote__location">{{testimonial.location}}</p>
				</div>	
			</div>	
		</div>
	</div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Testimonials",

  created() {
    this.loadTestimonials(this.locale);
  },
  methods: {
    ...mapActions("testimonials", ["loadTestimonials"]),
  },
  computed: {
    ...mapState("testimonials", { testimonials: "all" }),
    ...mapState("locale", { locale: "locale" }),
  },
  watch: {
    locale() {
      this.loadTestimonials(this.locale);
    },
  },
};
</script>
<style lang="scss" scoped>
svg {
  fill: rgb(247, 90, 7);
  width: 40px;
  height: 40px;
}

img {
  max-height: 90px;
  max-width: 90px;
  border-radius: 50%;
}
.testimonials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 75%;
  margin-right: auto;
  margin-left: auto;
  color: #555555;

  &__heading-box {
    display: flex;
    justify-content: center;
    font-size: 48px;
    padding-bottom: 50px;
  }

  &__quotes-box {
    display: flex;
    align-items: center;
    justify-content: center;

    &__quote {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 25%;
      padding: 10px;

      &__text {
        text-align: center;
        font-style: italic;
        font-weight: 400;
        font-size: 15px;
        padding: 15px 25px;
      }
      &__name {
        font-weight: 600;
        font-size: 1.125em;
        line-height: 1.55;
        color: rgb(23, 37, 42);
        text-align: center;
      }
      &__location {
        font-weight: 400;
        color: rgb(37, 41, 42);
        text-align: center;
      }
    }
  }
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-width: 768px) {
  .testimonials__quotes-box {
    flex-direction: column;
    &__quote {
      max-width: 100%;
    }
  }
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .testimonials__quotes-box {
    flex-direction: row;
    flex-wrap: wrap;
    &__quote {
      max-width: 45%;

      &__text {
        min-height: 275px;
      }
    }
  }
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
}
</style>
