<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('signup.shippingAddress')"
      />

      <div class="form-group">
        <v-checkbox
          v-model="sameShippingAddress"
          :label="$t('signup.sameAddress')"
        ></v-checkbox>
      </div>

      <div v-if="!sameShippingAddress" class="form-group">
        <div class="form-group__field">
          <v-text-field
            v-model="shippingCountry"
            :label="$t('signup.country')"
            :placeholder="$t('signup.country')"
            outlined
            dense
            readonly
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="shippingAddress"
            :label="`${$t('signup.address1')} *`"
            :placeholder="$t('signup.address1')"
            outlined
            dense
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="shippingAddress2"
            :label="$t('signup.address2')"
            :placeholder="$t('signup.address2')"
            outlined
            dense
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="shippingCity"
            :label="`${$t('signup.city')} *`"
            :placeholder="$t('signup.city')"
            outlined
            dense
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-select
            v-model="shippingRegion"
            :label="`${$t('signup.region')} *`"
            color="#f75007"
            :items="states"
            outlined
            dense
          ></v-select>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="shippingPostcode"
            :label="`${$t('signup.postcode')} *`"
            :placeholder="$t('signup.postcode')"
            outlined
            dense
            :maxlength="5"
            color="#f75007"
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/mixins/user.js";
import rules from "@/mixins/validation-rules.js";
import FormTitle from "@/components/common/FormTitle.vue";
import states from "@/data/states.js";

import { mapActions, mapState } from "vuex";

export default {
  name: "FormPersonalDetails",
  mixins: [user, rules],
  components: {
    FormTitle,
  },

  data() {
    return {
      sameAddress: false,
      states: states,
    };
  },

  methods: {
    ...mapActions("signup", ["setUserData", "setOptions"]),
  },

  computed: {
    ...mapState("signup", { userData: "userData", options: "options" }),

    shippingAddress: {
      get() {
        return this.userData.shippingAddress;
      },
      set(value) {
        this.setUserData({ key: "shippingAddress", value });
      },
    },

    shippingAddress2: {
      get() {
        return this.userData.shippingAddress2;
      },
      set(value) {
        this.setUserData({ key: "shippingAddress2", value });
      },
    },

    shippingCity: {
      get() {
        return this.userData.shippingCity;
      },
      set(value) {
        this.setUserData({ key: "shippingCity", value });
      },
    },

    shippingRegion: {
      get() {
        return this.userData.shippingRegion;
      },
      set(value) {
        this.setUserData({ key: "shippingRegion", value });
      },
    },

    shippingPostcode: {
      get() {
        return this.userData.shippingPostcode;
      },
      set(value) {
        this.setUserData({ key: "shippingPostcode", value });
      },
    },

    shippingCountry: {
      get() {
        return this.userData.shippingCountry;
      },
    },

    sameShippingAddress: {
      get() {
        return this.options.sameShippingAddress;
      },
      set(value) {
        this.setOptions({ key: "sameShippingAddress", value });
        this.setUserData({
          key: "shippingAddress",
          value: this.userData.address,
        });
        this.setUserData({
          key: "shippingAddress2",
          value: this.userData.address2,
        });
        this.setUserData({
          key: "shippingCity",
          value: this.userData.city,
        });
        this.setUserData({
          key: "shippingRegion",
          value: this.userData.region,
        });
        this.setUserData({
          key: "shippingPostcode",
          value: this.userData.postcode,
        });
      },
    },
  },

  watch: {
    shippingPostcode(newVal) {
      this.$emit("address-change", newVal);
    },
    shippingAddress(newVal) {
      this.$emit("address-change", newVal);
    },
    shippingAddress2(newVal) {
      this.$emit("address-change", newVal);
    },
    shippingCity(newVal) {
      this.$emit("address-change", newVal);
    },
    shippingRegion(newVal) {
      this.$emit("address-change", newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.form-group {
  padding: 0 20px;
}

.sponsor-details {
  padding: 0 20px 20px 35px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;

    &__field {
      width: 50%;
      padding: 5px 20px 5px 5px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 70%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: start;

    &__field {
      width: 30%;
      max-width: 30%;
      padding: 5px 15px;
    }
  }
}
</style>
