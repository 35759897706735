<template>
  <div class="notice notice--warning">
    <div>
      {{ $t("checkout.addressChanged") }}
    </div>
    <div class="notice__button">
      <v-btn elevation="2" @click="reloadRates" color="warning" x-small>{{
        $t("checkout.clickToReload")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoticeAddressChange",

  props: {
    message: {
      type: [String, Array],
      required: true,
    },
  },

  methods: {
    reloadRates() {
      this.$emit("reload-shipping-options");
    },
  },
};
</script>

<style lang="scss" scoped>
.notice {
  padding: 25px;
  display: flex;

  &__button {
    margin-left: 15px;
  }

  &--warning {
    border: 1px solid #ffd54f;
  }
}
</style>
