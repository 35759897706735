import ProductsService from "@/services/Products";

const state = () => ({
  all: [],
  shop: [],
  signup: []
});

const getters = {
  getProductById: state => id => {
    return state.all.find(product => product.id === id);
  },
  getShopProductById: state => id => {
    return state.shop.find(product => product.product_id === id);
  },
  getAllCategories: state => {
    const allCategories = state.shop.map(category => ({
      category_id: category.category_id,
      category: category.category,
      category_es: category.category_es,
    }));

    const uniqueCategories = allCategories.reduce((acc, category) => {
      if (!acc.find(c => c.category_id === category.category_id)) {
        acc.push(category);
      }
      return acc;
    }, []);

    return uniqueCategories;
  }
};

const actions = {
  loadProducts({ commit }, lang) {
    const products = ProductsService.getAll(lang).map( async product => {
      // product.img = require(`@/assets/images/products/products-${product.id}.jpg`);
      const productImageModule = await import(`@/assets/images/products/products-${product.id}.jpg`);
      // Access the default export of the dynamic import
      product.img = productImageModule.default;      
      return product;      
    });
    commit("setProducts", products);
  },

  async loadShopProducts({ commit }, lang) {
    const result = await ProductsService.getShopProducts(lang);

    const products = result.map(product => {
      product.description =
        lang === "en" ? product.description : product.description_es;
      product.category = lang === "en" ? product.category : product.category_es;
      return product;
    });

    commit("setShopProducts", products);
  },

  async loadSignupProducts({ commit }, lang) {
    const result = await ProductsService.getSignupProducts(lang);

    const products = result.map(product => {
      product.description =
        lang === "en" ? product.description : product.description_es;
      product.category = lang === "en" ? product.category : product.category_es;
      return product;
    });

    commit("setSignupProducts", products);
  }
};

const mutations = {
  setProducts(state, products) {
    state.all = products;
  },
  setShopProducts(state, products) {
    state.shop = products;
  },
  setSignupProducts(state, products) {
    state.signup = products;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
