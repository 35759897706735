<template>
  <div class="language-selector">
    <div class="language-selector" v-if="!mobile">
      <img
        v-if="locale === 'en'"
        src="@/assets/images/header/flag-en.png"
        alt="EN"
        @click.stop.prevent="isVisible = true"
      />
      <img
        v-if="locale === 'es'"
        src="@/assets/images/header/flag-es.png"
        alt="ES"
        @click.stop.prevent="isVisible = true"
      />

      <transition name="dropdown-fade">
        <ul v-if="isVisible" ref="dropdown" class="language-box">
          <li v-for="lang in languages" :key="lang.locale">
            <a
              href="#"
              class="language-box__list-item"
              @click.prevent="switchLocale(lang.locale)"
            >
              <img :src="lang.flag" :alt="lang.name" class="flag" />
              <span class="localLang">{{ lang.name }}</span>
            </a>
          </li>
        </ul>
      </transition>
    </div>
    <div
      class="language-selector"
      v-if="mobile && locale === 'es'"
      @click.prevent="switchLocale('en')"
    >
      <img src="@/assets/images/header/flag-en.png" alt="EN" />
    </div>
    <div
      class="language-selector"
      v-if="mobile && locale === 'en'"
      @click.prevent="switchLocale('es')"
    >
      <img src="@/assets/images/header/flag-es.png" alt="ES" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const flagEn = import("@/assets/images/header/flag-en.png");
const flagEs = import("@/assets/images/header/flag-es.png");


export default {
  name: "LanguageSelector",

  props: {
    mobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
      isVisible: false,
    };
  },

  methods: {
    ...mapActions("locale", ["setLocale"]),
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.setLocale(locale);
        this.isVisible = false;
      }
    },
  },

  computed: {
    locale() {
      return this.$i18n.locale;
    },
    languages() {
      return [
        {
          locale: "en",
          name: "English",
          flag: flagEn.default,
        },
        {
          locale: "es",
          name: "Español",
          flag: flagEs.default,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.language-selector {
  z-index: 10000;
  display: inline;
  position: relative;
  margin-bottom: 50px;

  &__container {
    display: flex;
  }

  img {
    height: 25px;
    margin-bottom: -6px;
    cursor: pointer;
  }
}

ul {
  text-align: left;
  min-width: 138px;
  border-radius: 8px;
  background: #f75007;
  z-index: 500;
  position: absolute;
  list-style: none;
  padding: 10px 10px !important;
  margin: 0px !important;
  top: 30px;
  left: 0px;
  li {
    padding: 5px 0;
  }
}

.language-box {
  user-select: none;
  padding: 5px 5px 5px -5px;
  width: max-content;
  box-shadow: 0px 3px 6px black;

  a {
    text-decoration: none;
    color: white;
  }

  &__list-item {
    span {
      margin-left: 10px;
    }
  }
}
</style>
