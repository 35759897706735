<template>
  <div>
    <Header />
    <MainNav />
    <PageTitle :title="$t('signup.thankYou')" />

    <FormTitle
      class="signup-container__title"
      :title="$t('signup.welcomeToBlw')"
    />
    <div class="container">
      <div>{{ newUser.fullname }},</div>
      <br />
      <div>
        {{ $t("signup.yourIdNumberIs") }}:
        <strong>{{ newUser.user_id }}</strong>
      </div>
      <div>
        {{ $t("signup.yourReplicatingSiteIs") }}:
        <a :href="siteUrl" target="_blank">{{ siteUrl }}</a>
      </div>
      <br />

      <p>{{ $t("signup.weArePleasedToHaveYou") }}</p>
      <p>{{ $t("signup.takeTheFirstStep") }}</p>
      <p>{{ $t("signup.askYourSponsor") }}</p>
      <p>{{ $t("signup.stayConnected") }}</p>
      <p>{{ $t("signup.blwsCareerIs") }}</p>
      <br />
      <p>{{ $t("signup.committedToYourSuccess") }}</p>
      <br />
      <p>
        Steve W. Moretta<br />
        {{ $t("signup.founderPresident") }} <br />
        <img src="@/assets/images/signup/signature.png" />
      </p>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import MainNav from "@/components/common/MainNav.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import FormTitle from "@/components/common/FormTitle.vue";
import { mapState } from "vuex";

import user from "@/mixins/user.js";

export default {
  name: "ShopThankYou",
  mixins: [user],
  components: {
    Header,
    Footer,
    MainNav,
    PageTitle,
    FormTitle,
  },

  computed: {
    ...mapState("signup", {
      newUser: "newUser",
      order: "order",
    }),

    siteUrl() {
      return `https://${this.newUser.site_id}.vidabestlife.com`;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 25px;
  text-align: left;

  a {
    color: #f75007;
    text-decoration: none;
  }
}

.signup-buttons {
  display: flex;
  flex-direction: column;
  padding: 10px 35px;
  &__title {
    padding: 10px;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    white-space: nowrap;
  }
}

@media only screen and (min-width: 900px) {
  .container {
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    &__title {
      margin-right: auto;
    }
  }
}
</style>
