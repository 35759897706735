<template>
  <div class="product-description">
    <div class="product-description__item">
      <div class="product-description__name">{{ product.name }}</div>
      <div class="product-description__description">
        <p v-for="(paragraph, index) in product.description" :key="index">
          {{ paragraph }}
        </p>
      </div>
      <v-btn
        v-if="userExists"
        color="#f75007"
        dark
        x-large
        class="product-description__button"
        to="/shop"
      >
        {{ $t("product.buyNow") }}
      </v-btn>
    </div>

    <div class="product-description__item ml-9">
      <div class="product-description__photo">
        <img
          class="products__box__image"
          :src="product.img"
          :alt="product.name"
        />
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/mixins/user.js";

export default {
  name: "ProductDescription",
  mixins: [user],

  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.product-description {
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
  color: #555555;
  text-align: center;
  align-items: center;

  &__name {
    font-size: 48px;
  }

  &__description {
    padding-top: 30px;
    text-align: justify;
    font-size: 18px;
  }

  &__button {
    margin-top: 15px;
  }

  &__item {
    max-width: 500px;
  }

  &__photo {
    img {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .product-description {
    flex-direction: row;
    justify-content: center;

    &__item {
      max-width: 500px;
      min-width: 500px;
    }

    &__photo {
      padding: 30px;

      img {
        width: 100%;
        border-top: 30px solid #f75007;
        border-right: 30px solid #f75007;
      }
    }
  }
}

@media only screen and (min-width: 1301px) {
  .product-description {
    flex-direction: row;
    justify-content: center;

    &__item {
      max-width: 700px;
      min-width: 500px;
    }

    &__photo {
      padding: 30px;

      img {
        width: 100%;
        border-top: 30px solid #f75007;
        border-right: 30px solid #f75007;
      }
    }
  }
}
</style>
