<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('signup.replicatedSiteUrl')"
      />

      <div class="form-group">
        <div class="form-group__field">
          <v-text-field
            v-model="siteUrl"
            :label="`${$t('signup.siteUrl')} *`"
            :placeholder="$t('signup.siteUrl')"
            outlined
            dense
            color="#f75007"
            suffix=".vidabestlife.com"
            :rules="[rules.required, rules.alphanumeric]"
          ></v-text-field>
        </div>
        <div class="form-group__text">
          When you become a registered BestLife Independent Brand Ambassador,
          you will have immediate access to your personal BestLife replicated
          website to direct your customers and potential new Independent Brand
          Ambassador to. Please enter your desired "site name" in the box above.
          For example if you want EmilySmith.vidabestlife.com enter "EmilySmith".
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/mixins/user.js";
import rules from "@/mixins/validation-rules.js";
import FormTitle from "@/components/common/FormTitle.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "FormSponsor",
  mixins: [user, rules],
  components: {
    FormTitle,
  },

  methods: { ...mapActions("signup", ["setUserData"]) },

  computed: {
    ...mapState("signup", { userData: "userData" }),

    siteUrl: {
      get() {
        return this.userData.siteId;
      },
      set(value) {
        this.setUserData({ key: "siteId", value });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.form-group {
  padding: 0 20px;
}

.sponsor-details {
  padding: 0 20px 20px 35px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;

    &__field {
      width: 50%;
      padding: 5px 20px 5px 5px;
    }

    &__text {
      text-align: left;
      padding: 5px 20px 5px 5px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 70%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: start;

    &__field {
      width: 30%;
      max-width: 30%;
      padding: 5px 15px;
    }

    &__text {
      text-align: left;
      max-width: 90%;
      padding: 5px 15px;
    }
  }
}
</style>
