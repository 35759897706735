<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('checkout.paymentMethod')"
      />

      <div class="customer-details">
        <div>{{ payment.name }}</div>
        <div>{{ maskedCardNumber }}</div>
        <div>{{ payment.expiry }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import FormTitle from "@/components/common/FormTitle.vue";

import { mapState } from "vuex";

export default {
  name: "ConfirmPaymentMethod",
  components: {
    FormTitle,
  },

  computed: {
    ...mapState("payment", { payment: "payment" }),

    maskedCardNumber() {
      const last4 = this.payment.number.substring(
        this.payment.number.length - 4
      );
      return `**** **** **** ${last4}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.strong {
  font-weight: 800;
}

.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.customer-details {
  padding: 0 20px 20px 35px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 60%;
  }
}
</style>
