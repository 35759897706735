<template>
  <div>
    <Header />
    <MainNav />
    <PageTitle title="Team" />
    <div class="people__title">{{ $t("people.ourTeam") }}</div>
    <div class="people__container">
      <Person v-for="(person, index) in people" :person="person" :key="index" />
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import MainNav from "@/components/common/MainNav.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import Person from "@/components/people/Person.vue";
import user from "@/mixins/user.js";

export default {
  name: "People",
  mixins: [user],
  components: {
    Header,
    Footer,
    MainNav,
    PageTitle,
    Person,
  },

  created() {
    this.loadPeople(this.locale);
  },

  methods: {
    ...mapActions("people", ["loadPeople"]),
  },

  computed: {
    ...mapState("people", { people: "all" }),
    ...mapState("locale", { locale: "locale" }),
  },

  watch: {
    locale() {
      this.loadPeople(this.locale);
    },
  },
};
</script>

<style lang="scss" scoped>
.people {
  &__title {
    padding: 20px 0 50px 0;
    font-size: 48px;
    text-transform: uppercase;
    text-align: center;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1100px) {
  .people {
    text-align: center;
    &__container {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }
  }
}
</style>
