<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('signup.personalDetails')"
      />

      <div class="form-group">
        <div class="form-group__field">
          <v-text-field
            v-model="name"
            :label="`${$t('signup.firstName')} *`"
            :placeholder="$t('signup.firstName')"
            outlined
            dense
            color="#f75007"
            :rules="[rules.required]"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="surname"
            :label="`${$t('signup.lastName')} *`"
            :placeholder="$t('signup.lastName')"
            outlined
            dense
            color="#f75007"
            :rules="[rules.required]"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="company"
            :label="$t('signup.company')"
            :placeholder="$t('signup.company')"
            outlined
            dense
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="ssn"
            :label="requireSSN ? `${$t('signup.ssn')}`: `${$t('signup.ssn')} *`"
            :placeholder="$t('signup.ssn')"
            outlined
            dense
            color="#f75007"
            :maxlength="9"
            :rules="[requireSSN]"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :label="`${$t('signup.dob')} *`"
                :placeholder="$t('signup.dob')"
                readonly
                outlined
                dense
                color="#f75007"
                v-bind="attrs"
                v-on="on"
                :rules="[rules.required]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              :active-picker="activePicker"
              color="#f75007"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              min="1950-01-01"
              @change="save"
            ></v-date-picker>
          </v-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/mixins/user.js";
import rules from "@/mixins/validation-rules.js";
import FormTitle from "@/components/common/FormTitle.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "FormPersonalDetails",
  mixins: [user, rules],
  components: {
    FormTitle,
  },

  data() {
    return {
      activePicker: null,
      date: null,
      menu: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  methods: {
    ...mapActions("signup", ["setUserData", "setSponsorData", "setOptions"]),
    save(date) {
      this.$refs.menu.save(date);
      this.setUserData({ key: "dob", value: date });
    },
  },

  computed: {
    ...mapState("signup", { userData: "userData", membershipType: "membershipType"}),

    name: {
      get() {
        return this.userData.name;
      },
      set(value) {
        this.setUserData({ key: "name", value });
      },
    },

    surname: {
      get() {
        return this.userData.surname;
      },
      set(value) {
        this.setUserData({ key: "surname", value });
      },
    },

    dob: {
      get() {
        return this.userData.dob;
      },
      set(value) {
        this.setUserData({ key: "dob", value });
      },
    },

    ssn: {
      get() {
        return this.userData.ssn;
      },
      set(value) {
        this.setUserData({ key: "ssn", value });
      },
    },

    company: {
      get() {
        return this.userData.company;
      },
      set(value) {
        this.setUserData({ key: "company", value });
      },
    },

    requireSSN() {
      return this.membershipType !== "brand_ambassador";
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.form-group {
  padding: 0 20px;
}

.sponsor-details {
  padding: 0 20px 20px 35px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;

    &__field {
      width: 50%;
      padding: 5px 20px 5px 5px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 70%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: start;

    &__field {
      width: 30%;
      max-width: 30%;
      padding: 5px 15px;
    }
  }
}
</style>
