<template>
  <div>
    <vue-flux
      :options="vfOptions"
      :images="vfImages"
      :transitions="vfTransitions"
      ref="slider"
    >
      <template v-slot:preloader>
        <flux-preloader />
      </template>
    </vue-flux>
  </div>
</template>

<script>
import { VueFlux, FluxPreloader } from "vue-flux";

import sliderImage1 from '@/assets/images/homepage/slider-image1.jpg';
import sliderImage2 from '@/assets/images/homepage/slider-image2-cropped.jpg';
import sliderImage3 from '@/assets/images/homepage/slider-image-3.jpg';
import sliderImage4 from '@/assets/images/homepage/slider-image-4.jpg';
import sliderImage7 from '@/assets/images/homepage/slider-image-7.jpg';
import sliderImage8 from '@/assets/images/homepage/slider-image-8.jpg';
import sliderImage9 from '@/assets/images/homepage/slider-image-9.jpg';

export default {
  name: "ImageSlider",
  components: {
    VueFlux,
    FluxPreloader,
  },

  data: () => ({
    vfOptions: {
      autoplay: true,
      delay: 2000,
    },
    vfImages: [
      sliderImage1,
      sliderImage2,
      sliderImage3,
      sliderImage4,
      sliderImage7,
      sliderImage8,
      sliderImage9,
    ],
    vfTransitions: ["fade"],
  }),
};
</script>

<style lang="scss" scoped></style>
