<template>
  <div
    class="notice"
    :class="{
      'notice--warning': isWarning,
      'notice--error': isError,
      'notice--success': isSuccess,
      'notice--info': isInfo,
    }"
  >
    <div v-if="isArray">
      <div :key="item" v-for="item in message">
        {{ item }}
      </div>
    </div>
    <div v-if="!isArray">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Notice",

  props: {
    message: {
      type: [String, Array],
      required: true,
    },

    type: {
      type: String,
    },
  },

  computed: {
    isWarning() {
      return this.type === "warning";
    },

    isInfo() {
      return this.type === "info";
    },

    isError() {
      return this.type === "error";
    },

    isSuccess() {
      return this.type === "success";
    },

    isArray() {
      return this.message instanceof Array;
    },
  },
};
</script>

<style lang="scss" scoped>
.notice {
  padding: 25px;

  &--info {
    border: 1px solid #4fc3f7;
  }

  &--warning {
    border: 1px solid #ffd54f;
  }

  &--error {
    border: 1px solid #ffab91;
    background-color: #ffab91;
    font-weight: 600;
  }

  &--success {
    border: 1px solid #a5d6a7;
  }
}
</style>
