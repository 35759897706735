import Api from "./Api";

const blwApi = process.env.VUE_APP_BLW_API;

class ShopService {
  async getShippingRates(params) {
    const response = await Api().post(
      `${blwApi}/shop/getShippingOptions/`,
      params
    );

    try {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Could not fetch shipping options");
      }
    } catch (e) {
      this.error = e;
    }
  }

  async createOrder(params) {
    const response = await Api().post(`${blwApi}/shop/createOrder/`, params);

    try {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Could not create order");
      }
    } catch (e) {
      this.error = e;
    }
  }
}

export default new ShopService();
