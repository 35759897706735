<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('signup.bbaAgreement')"
      />

      <div class="form-group">
        <v-checkbox
          v-model="agreedToTerms"
          :label="$t('signup.clickToAgree')"
        ></v-checkbox>
      </div>
      <div class="agreement-notice">
        {{ $t("signup.agreementNotice") }}
        <a href="#" @click="dialog = true">{{
          $t("signup.termsAndConditions")
        }}</a>
      </div>
    </div>
    <v-dialog
      color="#f75007"
      v-model="dialog"
      absolute
      persistent
      max-width="80%"
    >
      <v-card>
        <v-card-title color="#f75007" class="text-h5">
          {{ $t("signup.termsAndConditions") }}
        </v-card-title>
        <v-card-text>
          <v-textarea
            class="mt-5"
            color="#f75007"
            outlined
            name="input-7-4"
            readonly
            v-if="locale === 'en'"
            value="BestLife Worldwide Inc. Independent Executive Agreement Terms and Conditions

1. I understand that as a BestLife Worldwide Independent Executive: 

a. I have the right to offer for sale BestLife products and services in accordance with these Terms and Conditions. 

b. I have the right to enroll people in BestLife. 

c. I will train and motivate the Independent Executives in my downline marketing organization. 

d. I will comply with all federal, state, county and municipal laws, ordinances, rules, and regulations, and shall make all reports and remit all withholdings or other deductions as may be required by any federal, state, county or municipal law, ordinance, rule or regulation. 

e. I will perform my obligations as an Independent Executive with honesty and integrity. 

2. I agree to present the BestLife Marketing and Prosperity Plan and BestLife products and services as set forth in official BestLife literature. 

3. I agree that as a BestLife Independent Executive I am an independent contractor, and not an employee, agent, partner, legal representative, or franchisee of BestLife. I am not authorized to and will not incur any debt, expense, obligation, or open any checking account on behalf of, for, or in the name of BestLife. I agree that I will be solely responsible for paying all expenses incurred by myself, including but not limited to travel, food, lodging, secretarial, office, long distance telephone and other expenses. I UNDERSTAND THAT I SHALL NOT BE TREATED AS AN EMPLOYEE OF BESTLIFE FOR FEDERAL OR STATE TAX PURPOSES. BestLife is not responsible for withholding, and shall not withhold or deduct from my bonuses and commissions, if any, FICA, or taxes of any kind. 

4. Upon receipt I have carefully read and agree to comply with the BestLife Policies and Procedures and the BestLife Marketing and Prosperity Plan, both of which are incorporated into and made a part of these Terms and Conditions (these three documents shall be collectively referred to as the Agreement ). I understand that I must be in good standing, and not in violation of the Agreement, to be eligible for bonuses or commissions from BestLife. I understand that these Terms and Conditions, the BestLife Policies and Procedures, or the BestLife Marketing and Prosperity Plan may be amended at the sole discretion of BestLife, and I agree that any such amendment will apply to me. Notification of amendments shall be published in official BestLife materials. The continuation of my BestLife business or my acceptance of bonuses or commissions shall constitute my acceptance of any and all amendments. 

5. The term of this agreement is in place until I cancel or terminate my membership, or if it is canceled or terminated for any reason. If it is canceled or terminated I understand that I will lose my rights as an Independent Executive. I shall not be eligible to sell BestLife products and services nor shall I be eligible to receive commissions, bonuses, or other income resulting from the activities of my former downline sales organization. In the event of cancellation, or termination, I waive all rights I have, including but not limited to my former downline organization and to any bonuses, commissions or other remuneration derived through the sales and other activities of my former downline organization. BestLife reserves the right to terminate all Independent Executive Agreements upon 30 days notice if the Company elects to: (1) cease business operations, or (2) dissolve as a business entity.

6. I may not assign any rights or delegate my duties under the Agreement without the prior written consent of BestLife. Any attempt to transfer or assign the Agreement without the express written consent of BestLife renders the Agreement voidable at the option of BestLife and may result in termination of my business. 

7. I understand that if I fail to comply with the terms of the Agreement, BestLife may, at its discretion impose upon me disciplinary action as set forth in the Policies and Procedures. If I am in breach, default or violation of the Agreement at termination, I shall not be entitled to receive any further bonuses or commissions, whether or not the sales for such bonuses or commissions have been completed. 

8. BestLife, its directors, officers, shareholders, employees, assigns, and agents (collectively referred to as affiliates ), shall not be liable for, and I release BestLife and its affiliates from, all claims for consequential and exemplary damages. I further agree to release BestLife and its affiliates from all liability arising from or relating to the promotion or operation of my BestLife business and any activities related to it (e.g., the presentation of BestLife products or Marketing and Prosperity Plan, the operation of a motor vehicle, the lease of meeting or training facilities, etc.), and agree to indemnify BestLife for any liability, damages, fines, penalties, or other awards arising from any unauthorized conduct that I undertake in operating my business. 

9. The Agreement, in its current form and as amended by BestLife at its discretion, constitutes the entire contract between BestLife and myself. Any promises, representations, offers, or other communications not expressly set forth in the Agreement are of no force or effect. 

10. Any waiver by BestLife of any breach of the Agreement must be in writing and signed by an authorized officer of BestLife. Waiver by BestLife of any breach of the Agreement by me shall not operate or be construed as a waiver of any subsequent breach. 

11. If any provision of the Agreement is held to be invalid or unenforceable, such provision shall be reformed only to the extent necessary to make it enforceable and the balance of the Agreement will remain in full force and effect. Agreement will remain in full force and effect. 

12. This Agreement will be governed by and construed in accordance with the laws of the State of Texas, without regard to principles of conflicts of laws. All disputes and claims relating to BestLife, the Independent Executive Agreement, the BestLife Marketing and Prosperity Plan or its products and services, the rights and obligations of an Independent Executive and BestLife, or any other claims or causes of action relating to the performance of either an Independent Executive or BestLife under the Agreement or the BestLife Policies and Procedures shall be settled totally and finally by arbitration in Houston, Texas, or such other location as BestLife prescribes, in accordance with the Federal Arbitration Act and the Commercial Arbitration Rules of the American Arbitration Association, except that all parties shall be entitled to discovery rights allowed under the Federal Rules of Civil Procedure. All issues related to arbitration shall be governed by the Federal Arbitration Act. The decision of the arbitrator shall be final and binding on the parties and may, if necessary, be reduced to a judgment in any court of competent jurisdiction. Each party to the arbitration shall be responsible for its own costs and expenses of arbitration, including legal and filing fees. This agreement to arbitrate shall survive any termination or expiration of the Agreement. Nothing in the Agreement shall prevent BestLife from applying to and obtaining from any court having jurisdiction a writ of attachment, a temporary injunction, preliminary injunction, permanent injunction or other relief available to safeguard and protect BestLife's interest prior to, during or following the filing of any arbitration or other proceeding or pending the rendition of a decision or award in connection with any arbitration or other proceeding. 

13. The parties consent to jurisdiction and venue before any federal or state court in Houston, Texas, for purposes of enforcing an award by an arbitrator or any other matter not subject to arbitration. 

14. Louisiana Residents Only: Notwithstanding the foregoing, Louisiana residents may bring an action against the Company with jurisdiction and venue as provided by Louisiana law. 

15. If an Independent Executive wishes to bring an action against BestLife for any act or omission relating to or arising from the Agreement, such action must be brought within one year from the date of the alleged conduct giving rise to the cause of action. Failure to bring such action within such time shall bar all claims against BestLife for such act or omission. Independent Executive waives all claims that any other statutes of limitations applies. 

16. I authorize BestLife to use my name, photograph, personal story and/or likeness in advertising/promotional materials and waive all claims for remuneration for such use. 

17. By completing and submitting this Application, I specifically authorize BestLife Worldwide Inc. to communicate with me by electronic mail at the email address I have entered on the front of the Application. I understand that such emails may include offers and solicitations for the sale and purchase of BestLife products, sales aids, and services. 

18. A faxed copy of the Agreement shall be treated as an original in all respects. Initials________________

19. For any orders placed there is a 30 day return policy. After the product/s have been returned and received and the claim is fully submitted, it will take 3-10 business days to process and another 2-5 business days to show up into your account. The product/s have to be properly sealed. In case the product/s have been opened, the company will accept returns only if there is at least 80% of the product still in the container. There will be a shipping charge and a 10% restocking fee.

Autoship Terms and Conditions (if applicable)

The following Terms and Conditions apply only to Applicants who have elected to participate in the BestLife Autoship Program. 

1. I authorize BestLife to withdraw payment for my monthly Autoship order indicated in this Agreement from my credit/debit card. 

2. I understand that my first Autoship order will be processed and shipped upon receipt by BestLife. 

3. I understand that I may cancel my Autoship participation within five (5) business days of the date of this Agreement and receive a full refund of any Autoship related amounts charged to my credit card, if any. Thereafter, refunds will be available as provided in the BestLife Policies and Procedures. 

4. To change my Autoship order selection, method of payment or the authorized amount, I understand that I must submit a new Autoship agreement to BestLife. If more than one such Agreement has been submitted, I agree that the most recent Agreement will supersede all previous Agreements. BestLife reserves the right to change its prices associated with its products without notice. 

5. This Agreement will remain in effect until you: (1) elect to alter or change any aspect of this Agreement by submitting a new Autoship Agreement; (2) send, in writing, your cancellation of your participation in the Autoship Program to BestLife Worldwide P.O. Box 25 Barker, Texas 77413 or by fax to (832) 213-2979 (Notice must include your signature, printed name, address, and Independent Executive Identification Number), or (3) stop payment of any withdrawals by BestLife by notifying your issuing bank at least three days prior to the scheduled charging of your account. Notice of cancellation must be received five (5) days prior to the next scheduled Autoship date in order to avoid charges for that month. If a cancellation notice is received in less than five days prior to the monthly Autoship date, cancellation will become effective in the month following the month in which your notice of cancellation is received by BestLife. 

6. I understand that applicable local and state sales taxes will be added to my Autoship order amount each month, based on the address to which my Autoship orders are sent. I authorize BestLife to add such amount to the amount charged to my credit card each month. 

7. I understand that shipping and handling charges will be added to my Autoship order amount each month in accordance with the method of shipping that I have selected in this Application and Agreement. I authorize BestLife to charge my debit/credit card such amount each month. 
* By entering my Social Security (or Federal Tax Identification Number, if applicable) on this Independent Executive Application and Agreement, I certify that this number is my correct taxpayer identification number. I have not been a BestLife Independent Executive, or a partner, shareholder, or principal of any entity having a BestLife business within the past six months. I understand that any intentional misrepresentation of any information I provide on this Independent Executive Application and Agreement may result in action by BestLife, up to and including termination of this Agreement. 

I have reviewed the Contract and understand that I will be subject to BestLife's terms and conditions, and policies and procedures. 


Last update: January 2020

"
          ></v-textarea>
          <v-textarea
            class="mt-5"
            color="#f75007"
            outlined
            name="input-7-4"
            readonly
            v-if="locale === 'es'"
            value="Acuerdo Asociado Independiente de Negocio (AIN) a Términos y Condiciones de BestLife Worldwide Inc. 

1. Entiendo q1ue como Asociado Independiente de Negocio de BestLife Worldwide:

a. Tengo el derecho de ofrecer para la venta productos de BestLife y servicios de acuerdo con estos Términos y Condiciones. 

b. Tengo el derecho de matricular a personas en BestLife. 

c. Entrenaré y motivaré a los Asociados Independientes de Negocio en mi organización de mercadeo multi-nivel (Línea Descendente). 

d. Cumpliré con todas las leyes, ordenanzas, normas y reglamentos y remitiré todos los informes y retenciones u otras deducciones que requiera por cualquier ley, norma o reglamento. 

e. Realizaré mis obligaciones como un Asociado Independiente de Negocio con honestidad e integridad. 
2. Consiento en presentar el Plan de Prosperidad y Mercadotecnia de BestLife y los productos y servicios de BestLife como está expuesto en la literatura oficial de BestLife. 

3. Estoy de acuerdo que como un Asociado Independiente de Negocio de BestLife soy un contratista independiente y no un empleado, agente, socio, representante legal o concesionario de BestLife. No estoy autorizado para incurrir cualquier deuda, gastos, obligación o abrir cualquier cuenta de cheques en nombre de, para o de parte de BestLife. Estoy de acuerdo en que seré exclusivamente responsable de pagar todos los gastos incurridos por mí mismo, incluyendo pero no limitado a viajar, comida, alojamiento, secretarial, oficina, servicio telefónico de larga distancia y otros gastos. ENTIENDO QUE NO VOY A SER TRATADO COMO UN EMPLEADO DE BESTLIFE PARA FINES DE IMPUESTOS E INDEMNIZACION LABORAL DE NINGUN TIPO Y NO RECIBIR COBERTURA PATRONAL. BestLife no es responsable de la retención, y no debe retener o descontar de mis sobresueldos y comisiones, si alguno, Fica o impuestos de cualquier clase. 

4. Al recibimiento de este documento, he leído con cuidado y consiento en cumplir con las Políticas y Procedimientos y el Plan de Prosperidad y Mercadotecnia de BestLife, ambos de los cuales son incorporados y forman parte de estos Términos y Condiciones (estos tres documentos deben ser colectivamente referidos como el Acuerdo). Entiendo que debo estar en buena posición y no en violación del Acuerdo para ser elegible para sobresueldos o comisiones por parte de BestLife. Entiendo que estos Términos y Condiciones, las Políticas y Procedimientos o el Plan de Prosperidad y Mercadotecnia de BestLife pueden ser enmendados a la propia discreción de BestLife, y estoy de acuerdo que cualquier enmienda se aplicará a mí. La notificación de enmiendas debe ser publicada en materiales oficiales de BestLife. La continuación de mi negocio de BestLife o mi aceptación de sobresueldos o comisiones debe constituir mi aceptación de cualquiera y todas las enmiendas. ). I understand that I must be in good standing, and not in violation of the Agreement, to be eligible for bonuses or commissions from BestLife. I understand that these Terms and Conditions, the BestLife Policies and Procedures, or the BestLife Marketing and Prosperity Plan may be amended at the sole discretion of BestLife, and I agree that any such amendment will apply to me. Notification of amendments shall be published in official BestLife materials. The continuation of my BestLife business or my acceptance of bonuses or commissions shall constitute my acceptance of any and all amendments. 

5. El término de este acuerdo sigue en pie hasta que yo anule o termine mi membrecía, o si es anulado o terminado por cualquier motivo. Si es anulado o terminado entiendo que perderé mis derechos como un Asociado Independiente de Negocio. No seré elegible para vender productos y servicios de BestLife o ser elegible para recibir comisiones, sobresueldos, u otros ingresos que resultan de las actividades de mi ex-organización de ventas multi-nivel (downline). En caso de la cancelación, o terminación, renuncio a todos los derechos que tengo, incluso, pero no limitado con mi ex-organización multi-nivel (downline) y con cualquier sobresueldo, comisiones u otra remuneración a través de las ventas y otras actividades de mi ex-organización multi-nivel. BestLife reserva el derecho a rescindir todos los Acuerdos de Asociado Independiente de Negocio con previo aviso de 30 días si la compañía elige: (1) interrumpir las operaciones del negocio, o (2) disolver como una entidad empresarial. 

6. No puedo asignar ningún derecho o delegar mis deberes en virtud del acuerdo sin el previo consentimiento por escrito de BestLife. Cualquier intento de transferir o asignar el Acuerdo sin el consentimiento expreso por escrito de BestLife hace el Acuerdo nulo en la opción de BestLife y puede dar lugar a la terminación de mi negocio. 

7. Entiendo que si dejo de cumplir con los Términos del Acuerdo, BestLife puede, en su discreción, imponer medida disciplinaria como expuesto en las Políticas y Procedimientos. Si estoy en infracción, falta o violación del Acuerdo en la terminación, no tendré derecho a recibir sobresueldos o comisiones, si o no las ventas para tales sobresueldos o comisiones se han completado. 

8. BestLife, sus directores, oficiales, accionistas, empleados, cesionarios, y agentes (colectivamente referido como se afila), no será obligado de, y libero a BestLife y sus afiliados de, todas las reclamaciones de daños consiguientes y ejemplares. Estoy de acuerdo además de liberar a BestLife y sus afiliados de toda responsabilidad que pueda derivarse de o relacionados con la promoción u operación de mi negocio BestLife y cualquier actividad relacionada con ella (por ejemplo, la presentación de productos de BestLife o el Plan de Prosperidad y Mercadotecnia, la operación de un vehículo de motor, la concesión de facilidades de reunión o formación, etc. ), y acepta indemnizar BestLife por cualquier responsabilidad, daños y perjuicios, multas, sanciones, u otros premios derivados de cualquier conducta no autorizada que me comprometo en mi negocio operativo. ), shall not be liable for, and I release BestLife and its affiliates from, all claims for consequential and exemplary damages. I further agree to release BestLife and its affiliates from all liability arising from or relating to the promotion or operation of my BestLife business and any activities related to it (e.g., the presentation of BestLife products or Marketing and Prosperity Plan, the operation of a motor vehicle, the lease of meeting or training facilities, etc.), and agree to indemnify BestLife for any liability, damages, fines, penalties, or other awards arising from any unauthorized conduct that I undertake in operating my business. 

9. El acuerdo, en su forma actual y modificada por BestLife a su discreción, constituye el contrato completo entre BestLife y yo. Cualquier promesa, representaciones, ofertas u otras comunicaciones no expresamente enunciadas en el Acuerdo son de ninguna fuerza o efecto. 

10. Cualquier renuncia por BestLife de cualquier violación del Acuerdo debe ser por escrito y firmada por un oficial autorizado de BestLife. La renuncia por BestLife de cualquier violación del Acuerdo por mí no debe funcionar o ser interpretada como una renuncia hacia cualquier violación subsecuente. 

11. Si alguna disposición del Acuerdo se considera no válida o inaplicable, dicha disposición deberá ser reformada sólo en la medida necesaria para hacerla ejecutable y el equilibrio del Acuerdo permanecerá en pleno efecto y vigor. El Acuerdo permanecerá en pleno efecto y vigor. 

12. El presente acuerdo se regirá e interpretará de conformidad con las leyes del Estado de Texas. Todas las disputas y las reclamaciones relativas a BestLife, el Acuerdo Asociado Independiente de Negocio, el Plan de Prosperidad y Mercadotecnia de BestLife o sus productos y servicios, los derechos y obligaciones de un Asociado Independiente de Negocio y BestLife, o cualquier otras reclamaciones o causas de acción relativas al desempeño de un Asociado Independiente de Negocio o BestLife bajo el acuerdo o las Políticas y Procedimientos de BestLife debe ser arreglado y finalizado en su totalidad por arbitraje en Houston, Texas o cualquier otro lugar que BestLife indique, en acuerdo con el Acta Federal de Arbitraje y las Reglas comerciales de Arbitraje de la Asociación Americana de Arbitraje, a excepción de que todas las partes esten autorizadas a los documentos descubiertos de acuerdo a los procedimientos de Normas Civiles Federales. Todos los asuntos relacionados al arbitraje deben ser regidos por el Acta Federal de Arbitraje. La decisión del árbitro será final y vinculante en las partes y puede, si es necesario, ser reducida a un juicio en cualquier jurisdicción competente. Cada parte del arbitraje será responsable de sus propios costos y gastos de arbitraje, incluyendo honorarios legales y de representación. Este acuerdo de arbitraje sobrevivirá a cualquier terminación o expiración del Acuerdo. Nada en el Acuerdo deberá prevenir que BestLife aplique u obtenga de cualquier tribunal que tenga competencia una orden judicial de embargo, un interdicto preliminar, un interdicto permanente u otra orden de beneficio para salvaguardar y proteger los intereses de BestLife con anterioridad, durante o a continuación de la tramitación de cualquier arbitraje u otro procedimiento o mientras se espere la decisión final o indemnización en relación a cualquier arbitraje u otro procedimiento.

13. Las partes acceden a la jurisdicción y al lugar ante cualquier corte Estatal o Federal en Houston, Texas para propósitos de hacer cumplir un fallo por un arbitro o cualquier otro asunto no relacionado con el arbitraje. 

14. Para los residentes de Luisiana únicamente: no obstante lo anteriormente mencionado, los residentes de Luisiana pueden tomar acción legal en contra de la compañía con jurisdicción y lugar como lo indique la ley de Luisiana.

15. Si un Asociado Independiente desea entablar una demanda contra BestLife por cualquier acto u omisión que se relaciona o proviene del Acuerdo, tal acción debe ser presentada dentro de un año desde la fecha de la presunta conducta dando lugar a la causa de acción judicial. Si hay una falla al presentar dicha acción dentro del tiempo asignado deberá excluir todas las reclamaciones contra BestLife para tal acto u omisión. El Asociado Independiente de Negocio renuncia todas las reclamaciones que cualquier otra ley de prescripción se aplica.

16. Autorizo a BestLife a utilizar mi nombre, fotografía, historia personal o semejanza en materiales de publicidad/promoción y renunciar a todas las reclamaciones por remuneración para dicho uso. 

17. Al completar y presentar esta aplicación, autorizo específicamente a BestLife Worldwide Inc. a comunicarse conmigo por medio de correo electrónico en la dirección de correo electrónico que he proveído en la aplicación. Entiendo que tales correos electrónicos pueden incluir ofertas y solicitaciones para la venta (rebajas) y la compra de productos de BestLife, recursos de ventas, y servicios. 

18. Una copia del Acuerdo enviada por fax se considerará como original en todos los aspectos. 

19. Para cualquier pedido realizado, existe una política de devolución de 30 días. Una vez que se hayan devuelto y recibido los productos y se haya enviado la reclamación en su totalidad, el proceso demorará entre 3 y 10 días hábiles y entre 2 y 5 días hábiles para que aparezcan en su cuenta. Los productos deben estar sellados correctamente. En caso de que los productos hayan sido abiertos, la empresa aceptará devoluciones solo si queda al menos el 80% del producto en el contenedor. Habrá un cargo de envío y una tarifa de reposición del 10%.

Términos y Condiciones de Envió Automático (si aplica) 

Los siguientes términos y condiciones se aplican sólo a los solicitantes que han elegido participar en el Programa de Envió Automático de BestLife. 

1. Autorizo a BestLife a retirar el pago de mi orden de envío automático mensual indicado en el presente Acuerdo de mi tarjeta de crédito/débito. 

2. Entiendo que mi primer envío automático será procesado y enviado al ser recibido por BestLife. 

3. Entiendo que puedo cancelar mi participación de envío automático dentro de cinco (5) días de la fecha de este acuerdo y recibir un reembolso completo de cualquier envío automático relacionado con cargos a mi tarjeta de crédito/debito, si alguno. Posteriormente, las restituciones estarán disponibles en las Políticas y Procedimientos de BestLife. 

4. Para cambiar mi orden de Envío Automático, método de pago o el monto autorizado, entiendo que debo presentar un nuevo acuerdo de Envío Automático a BestLife. Si más de un acuerdo de este tipo se ha presentado, estoy de acuerdo en que el más reciente Acuerdo reemplazará todos los acuerdos anteriores. BestLife se reserva el derecho de cambiar sus precios relacionados con sus productos sin previo aviso.

5. Este Acuerdo permanecerá en vigor hasta que usted: (1) elija alterar o modificar cualquier aspecto del presente Acuerdo mediante la presentación de un nuevo Acuerdo de Envío Automático; (2) envié por escrito la cancelación de su participación en el programa a BestLife Worldwide PO Box 54 Barker, Texas 77413 o por fax al 832-213-2979 ( el aviso correspondiente debe incluir su firma, nombre impreso, dirección y número de identificación de Asociadoo Independiente de Negocio), o (3) detenga el pago de las sumas retiradas por BestLife notificando a su banco emisor por lo menos tres días antes de la carga de su cuenta. Aviso de cancelación debe ser recibido cinco (5) días antes de la fecha siguiente del envío automático programado con el fin de evitar los cargos de ese mes. Si un aviso de cancelación es recibido en menos de cinco días antes de la fecha mensual del Envío Automático, la cancelación se hará efectiva en el mes siguiente al mes en que la notificación de cancelación es recibida por BestLife. 

6. Entiendo que los impuestos de ventas locales y estatales que apliquen serán añadidos a la cantidad de mi pedido (orden) de Envío Automático cada mes, basado en la dirección a la cual mis pedidos (órdenes) de Envío Automático son enviados. Autorizo a BestLife a añadir tal cantidad a la cantidad cargada a mi tarjeta de crédito cada mes.

7. Entiendo que gastos de envío y tramitación serán añadidos a la cantidad de mi orden de Envío Automático cada mes en conformidad con el método de envío que he seleccionado para esta Aplicación y Acuerdo. Autorizo a BestLife a cobrar a mi tarjeta de crédito/débito dicha cantidad cada mes o por compra efectuada. 

* Al proveer mi número de Seguro Social o (Número de Identificación Federal para impuestos, si aplica) en esta Aplicación y Acuerdo de Asociado Independiente de Negocio, certifico que ese dato es mi numero correcto de identificación para el pago de impuesto. Y no he sido un Asociado Independiente de Negocio de BestLife, o un socio, accionista principal, o de cualquier entidad que tenga un negocio de BestLife en los últimos seis meses. Tengo entendido que cualquier tergiversación intencional de toda la información que yo proporcione en esta Aplicación y Acuerdo de Asociado Independiente de Negocio puede resultar en una acción por BestLife, que se prolongará hasta la terminación del presente Acuerdo.


Ultima actualización: Octubre 2020"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="(agreement = false), (dialog = false)">
            {{ $t("signup.close") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="white--text" color="#f75007" @click="agree">
            {{ $t("signup.iAgree") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import user from "@/mixins/user.js";
import FormTitle from "@/components/common/FormTitle.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "FormAgreement",
  mixins: [user],
  components: {
    FormTitle,
  },

  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    ...mapActions("signup", ["setOptions"]),

    agree() {
      this.setOptions({ key: "agreedToTerms", value: true });
      this.dialog = false;
    },
  },

  computed: {
    ...mapState("signup", { options: "options" }),
    ...mapState("locale", { locale: "locale" }),

    agreedToTerms: {
      get() {
        return this.options.agreedToTerms;
      },
      set(value) {
        this.setOptions({ key: "agreedToTerms", value });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.form-group {
  padding: 0 20px;
}

.agreement-notice {
  padding: 0 20px;
  font-size: 12px;
  text-align: center;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;

    &__field {
      width: 50%;
      padding: 5px 20px 5px 5px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 70%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: start;

    &__field {
      width: 30%;
      max-width: 30%;
      padding: 5px 15px;
    }
  }
}
</style>
