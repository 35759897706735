<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('signup.enrollmentOptions')"
      />
      <v-radio-group v-model="selectedProduct">
        <div
          v-for="product in signup"
          :key="product.product_id"
          class="form-group"
        >
          <div class="form-group__item">
            <div class="form-group__item__radio vertical-center">
              <v-radio :value="product.product_id"></v-radio>
            </div>
            <div class="form-group__item__image vertical-center">
              <img :src="product.image" />
            </div>
            <div class="form-group__item__name vertical-center">
              <span>{{ product.name }}</span> - ${{
                formatNumber(product.price)
              }}
            </div>
          </div>
        </div>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import user from "@/mixins/user.js";
import FormTitle from "@/components/common/FormTitle.vue";
import { formattingMixin } from "@/mixins/formatting.js";

import { mapActions, mapState } from "vuex";

export default {
  name: "FormProductSelection",
  mixins: [user, formattingMixin],
  components: {
    FormTitle,
  },

  // data: () => ({
  //   radios: 717,
  // }),

  async created() {
    await this.loadSignupProducts(this.locale);
  },

  methods: {
    ...mapActions("products", ["loadSignupProducts"]),
    ...mapActions("signup", ["setProduct"]),
  },

  computed: {
    ...mapState("products", ["signup"]),
    ...mapState("signup", ["product"]),
    ...mapState("locale", { locale: "locale" }),

    selectedProduct: {
      get() {
        return this.product ? this.product.product_id : undefined;
      },
      set(value) {
        const product = this.signup.find((prod) => prod.product_id === value);
        this.setProduct(product);
        this.$emit("product-change", product);
      },
    },
  },
  watch: {
    locale() {
      this.loadSignupProducts(this.locale);
    }
  },
};
</script>

<style lang="scss" scoped>
.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-group {
  padding: 0 20px;

  &__item {
    display: flex;
    padding: 5px 10px;

    img {
      max-width: 50px;
    }

    &__radio {
      padding-right: 10px;
    }

    &__name {
      vertical-align: middle;
      padding-left: 15px;

      span {
        font-weight: 600;
      }
    }
  }
}

.sponsor-details {
  padding: 0 20px 20px 35px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;

    &__field {
      width: 50%;
      padding: 5px 20px 5px 5px;
    }

    &__text {
      text-align: left;
      padding: 5px 20px 5px 5px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 70%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: start;

    &__field {
      width: 30%;
      max-width: 30%;
      padding: 5px 15px;
    }

    &__text {
      text-align: left;
      max-width: 90%;
      padding: 5px 15px;
    }
  }
}
</style>
