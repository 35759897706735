<template>
  <div>
    <Header />
    <MainNav />
    <PageTitle :title="$t('checkout.checkoutConfirmation')" />

    <ConfirmPersonalDetails />
    <ConfirmOrderDetail />
    <ConfirmShippingMethod v-if="!options.pickup" />
    <ConfirmPaymentMethod />
    <ConfirmOrderSummary />

    <div class="container mt-5" v-if="errors.length > 0">
      <div class="checkout-container__notice">
        <Notice :message="errors" type="error" />
      </div>
    </div>

    <div class="container">
      <div class="checkout-buttons">
        <div v-if="!isLoading" class="checkout-buttons__button">
          <v-btn
            class="button-huge"
            color="#f75007"
            dark
            rounded
            to="/shopping-cart"
          >
            {{ $t("checkout.backToCart") }}
          </v-btn>
        </div>

        <div v-if="!isLoading" class="checkout-buttons__button">
          <v-btn @click="submitOrder" class="button-huge" color="" dark rounded>
            {{ $t("checkout.completeOrder") }}
          </v-btn>
        </div>

        <div v-if="isLoading" class="checkout-buttons__spinner">
          <v-progress-circular
            :width="3"
            size="60"
            color="#f75007"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import MainNav from "@/components/common/MainNav.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import ConfirmPersonalDetails from "@/components/checkout/ConfirmPersonalDetails.vue";
import ConfirmOrderDetail from "@/components/checkout/ConfirmOrderDetail.vue";
import ConfirmShippingMethod from "@/components/checkout/ConfirmShippingMethod.vue";
import ConfirmPaymentMethod from "@/components/checkout/ConfirmPaymentMethod.vue";
import ConfirmOrderSummary from "@/components/checkout/ConfirmOrderSummary.vue";
import Notice from "@/components/common/Notice.vue";

import { mapActions, mapState, mapGetters } from "vuex";
import user from "@/mixins/user.js";

export default {
  name: "ShopCheckoutConfirm",
  mixins: [user],
  components: {
    Header,
    Footer,
    MainNav,
    PageTitle,
    ConfirmPersonalDetails,
    ConfirmOrderDetail,
    ConfirmShippingMethod,
    ConfirmPaymentMethod,
    ConfirmOrderSummary,
    Notice,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    ...mapActions("cart", ["createOrder"]),
    async submitOrder() {
      this.isLoading = true;
      await this.createOrder();
      this.isLoading = false;
    },
  },

  computed: {
    ...mapGetters("cart", {
      total: "total",
      hasShippingAddress: "hasShippingAddress",
    }),
    ...mapState("cart", {
      items: "items",
      customer: "customer",
      options: "options",
      errors: "errors",
      order: "order",
    }),
    ...mapGetters("payment", { hasPaymentMethod: "hasPaymentMethod" }),

    canContinue() {
      return (
        this.hasShippingAddress &&
        this.shipping.cost > 0 &&
        this.hasPaymentMethod
      );
    },
  },

  watch: {
    async order() {
      if (this.order) {
        this.$router.push("checkout-thankyou");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.checkout-buttons {
  display: flex;
  flex-direction: column;
  padding: 10px 35px;
  &__title {
    padding: 10px;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    white-space: nowrap;
  }
  &__button {
    margin-bottom: 15px;
    button {
      width: 100%;
    }
  }
  &__spinner {
    margin: 15px 0 25px 0;
  }
}

.button-huge {
  height: 50px !important;
  font-size: 24px !important;
}

@media only screen and (min-width: 900px) {
  .checkout-buttons {
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    width: 80%;
    text-align: center;
    &__title {
      margin-right: auto;
    }
    &__button {
      margin-left: 35px;
    }
  }
}
</style>
