import Api from "./Api";

const blwApi = process.env.VUE_APP_BLW_API;

class UserService {
  async getUserFromSiteId(siteId) {
    const response = await Api().get(`${blwApi}/users/getBySiteId/${siteId}`);

    try {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Could not fetch user");
      }
    } catch (e) {
      this.error = e;
    }
  }

  async register(params) {
    const response = await Api().post(`${blwApi}/signup/register/`, params);

    try {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Could not complete registration");
      }
    } catch (e) {
      this.error = e;
    }
  }

  async validateSignup(params) {
    const response = await Api().post(`${blwApi}/signup/validate/`, params);

    try {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Could not perform validation");
      }
    } catch (e) {
      this.error = e;
    }
  }

  async getSponsorById(id) {
    const response = await Api().get(`${blwApi}/signup/getSponsorById/${id}`);

    try {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Could not fetch sponsor");
      }
    } catch (e) {
      this.error = e;
    }
  }
}

export default new UserService();
