<template>
  <div class="products">
    <!-- <a id="products"></a>
    <div class="products__title">{{ $t("homepage.products") }}</div>

    <div class="products__container">
      <div class="products__box" v-for="product in products" :key="product.id">
        <router-link
          :to="{ name: 'product', params: { productId: product.id } }"
        >
          <img
            class="products__box__image"
            :src="product.img"
            :alt="product.name"
          />
          <div class="products__box__name">{{ product.name }}</div>
        </router-link>
      </div>
    </div> -->

    <div class="products__button" v-if="userExists">
      <v-btn
        color="#f75007"
        dark
        x-large
        class="products__button--huge"
        to="/shop"
      >
        {{ $t("homepage.orderNow") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import user from "@/mixins/user.js";

export default {
  name: "Products",
  mixins: [user],

  created() {
    this.loadProducts(this.locale);
  },
  methods: { ...mapActions("products", ["loadProducts"]) },
  computed: {
    ...mapState("products", { products: "all" }),
    ...mapState("locale", { locale: "locale" }),
  },
  watch: {
    locale() {
      this.loadProducts(this.locale);
    },
  },
};
</script>

<style lang="scss" scoped>
.products {
  padding: 25px 25px 50px 25px;
  color: #555555;

  a {
    color: #555555;
    text-decoration: none;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    padding: 20px 0 50px 0;
    font-size: 48px;
    text-transform: uppercase;
    text-align: center;
  }

  &__box {
    margin-left: 15px;
    display: flex;
    flex-direction: column;

    &__name {
      padding-top: 20px;
      font-size: 22px;
      font-weight: 600;
      text-align: center;
    }

    &__image {
      height: auto;
      width: 100%;
    }
  }

  &__button {
    padding: 80px;
    text-align: center;
  }

  &__button--huge {
    width: 100% !important;
    height: 70px !important;
    font-weight: bold;
    color: white !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1300px) {
  .products {
    &__container {
      justify-content: center;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__box {
      &__name {
        font-size: 18px;
      }
    }

    &__box {
      flex: 0 0 25%;
      max-width: 22%;
    }

    &__button--huge {
      width: initial;
      height: 70px !important;
      font-size: 22px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .products {
    &__container {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__box {
      &__name {
        font-size: 18px;
      }
    }

    &__box {
      flex: 0 0 25%;
      max-width: 22%;
    }

    &__button--huge {
      width: initial;
      height: 70px !important;
      font-size: 22px;
    }
  }
}
</style>
