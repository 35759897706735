<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('checkout.orderSummary')"
      />

      <div class="order-summary">
        <div>
          {{ $t("checkout.product") }}:
          <span class="order-summary__price"
            >${{ formatNumber(total,2) }}</span
          >
        </div>
        <div>
          {{ $t("checkout.shipping") }}:
          <span class="order-summary__price"
            >${{ formatNumber(shipping.cost,2) }}</span
          >
        </div>
        <div>
          {{ $t("checkout.salesTax") }}:
          <span class="order-summary__price"
            >${{ formatNumber(totalTax,2) }}</span
          >
        </div>
        <div>
          {{ $t("checkout.creditCardFee") }} (4%):
          <span class="order-summary__price">${{ formatNumber(creditCardFee, 2) }}</span>
        </div>        
        <div class="order-summary__total">
          {{ $t("checkout.orderTotal") }}:
          <span class="order-summary__price"
            >${{ formatNumber(orderTotal,2) }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormTitle from "@/components/common/FormTitle.vue";
import { formattingMixin } from "@/mixins/formatting.js";

import { mapGetters, mapState } from "vuex";

export default {
  name: "ConfirmOrderSummary",
  components: {
    FormTitle,
  },

  mixins: [formattingMixin],

  computed: {
    ...mapGetters("cart", {
      total: "total",
      totalTax: "totalTax",
      shipping: "shipping",
      orderSubtotal: "orderSubtotal",
      orderTotal: "orderTotal",
      creditCardFee: "creditCardFee",
    }),

    ...mapState("cart", { shipping: "shipping" }),
  },
};
</script>

<style lang="scss" scoped>
.strong {
  font-weight: 800;
}

.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.order-summary {
  border: 1.5px solid #f75007;
  padding: 20px 40px;
  margin: 10px;
  text-align: right;
  font-size: 17px;
  &__price {
    margin-left: 30px;
  }

  &__total {
    border-top: 1px solid rgb(221, 221, 221);
    margin-top: 5px;
    padding-top: 5px;
    font-weight: 700;
    font-size: 18px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 60%;
  }
}
</style>
