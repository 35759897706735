export default {
  data() {
    return {
      rules: {
        required: value => !!value || this.$t("rules.required"),

        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("rules.invalidEmail");
        },

        alphanumeric: value => {
          const pattern = /^[a-z0-9A-Z]+$/;
          return pattern.test(value) || this.$t("rules.charsAndNumbersOnly");
        }
      }
    };
  }
};
