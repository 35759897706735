<template>
  <div class="signup-banner">
    <div class="signup-banner__title">{{ $t("homepage.joinUs") }}</div>
    <v-btn rounded color="#f75007" dark x-large to="signup">
      {{ $t("homepage.getStartedToday") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SignupBanner",
};
</script>

<style lang="scss" scoped>
.signup-banner {
  padding: 140px 100px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #12181b;

  &__title {
    font-size: 36px;
    padding: 0 40px;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-width: 768px) {
  .signup-banner {
    flex-direction: column;
    padding: 90px 0;
    text-align: center;
    line-height: 1.2;

    &__title {
      margin-bottom: 25px;
    }
  }
}
</style>
