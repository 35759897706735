<template>
  <tr>
    <td class="cart-body-table__image">
      <img :src="item.product.img" />
    </td>
    <td class="cart-body-table__description">
      <div class="cart-body__item__description__title">
        {{ item.product.name }}
      </div>
      <div class="cart-body__item__description__code">
        {{ item.product.code }}
      </div>
      <div class="cart-body__item__description__remove">
        <a @click="removeFromCart(item.product)">{{ $t('cart.remove') }}</a>
      </div>
    </td>
    <td class="cart-body-table__quantity">
      <v-text-field
        v-model="quantity"
        label=""
        type="number"
        dense
        outlined
        :min="1"
      ></v-text-field>
    </td>
    <td class="cart-body-table__volume">{{ item.product.points }}</td>
    <td class="cart-body-table__price">
      <div>${{ item.product.price }}</div>
      <div>${{ totalPrice }}</div>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import { formattingMixin } from '../../mixins/formatting'

export default {
  name: 'CartTableRow',

  mixins: [formattingMixin],

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions('cart', ['addToCart', 'removeFromCart'])
  },

  computed: {
    totalPrice () {
      return this.formatNumber(this.item.product.price * this.item.quantity)
    },

    quantity: {
      get () {
        return this.item.quantity
      },
      set (value) {
        this.addToCart({ product: this.item.product, quantity: value })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-container {
  // text-align: center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // border-bottom: 1px solid #ccc;
}

.cart-body {
  padding: 25px 20px;
  display: block;

  &__item {
    display: flex;

    &__image {
      padding-top: 15px;
      img {
        width: 40px;
      }
    }

    &__description {
      padding: 15px;
      text-align: left;

      &__title {
        font-size: 18px;
        font-weight: 700;
      }

      &__code {
        color: #999;
      }

      &__remove {
        a {
          text-decoration: none;
          color: #f75007;
        }
      }
    }

    &__price {
      &__counter {
        padding-top: 15px;
        // width: 50%;
      }

      &__summary {
        text-align: right;
      }
    }
  }
}

.cart-body-table {
  // width: 80%;

  font-size: 20px;
  margin-top: 30px;
  display: none;

  &__image {
    width: 10%;
    img {
      width: 70px;
    }
  }

  td {
    vertical-align: top;
    padding: 20px 40px;
  }

  &__description {
    width: 35%;
    text-align: left;
  }

  &__quantity {
    padding: 20px 10px !important;
    width: 10%;
  }

  &__volume {
    width: 15%;
    text-align: center;
  }
  &__price {
    text-align: right;
    width: 30%;
  }
}

@media only screen and (min-width: 900px) {
  .cart-body-table {
    width: 80%;
    font-size: 20px;
    margin-top: 30px;
    display: block;
  }

  table {
    margin-left: auto;
    margin-right: auto;
    // width: 100% !important;
    // min-width: 900px !important;
  }
  td {
    border-bottom: 1px solid #ccc;
  }
}
</style>
