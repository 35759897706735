<template>
  <div>
    <Header />
    <MainNav />
    <ShopTitle title="Shop" />
    <ShopProduct v-if="product" :product="product" />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import MainNav from "@/components/common/MainNav.vue";
import ShopTitle from "@/components/shop/ShopTitle.vue";
import ShopProduct from "@/components/shop/ShopProduct.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import user from "@/mixins/user.js";

export default {
  name: "ShopProductDetail",
  mixins: [user],
  components: {
    Header,
    Footer,
    MainNav,
    ShopTitle,
    ShopProduct,
  },

  data() {
    return {
      product: null,
    };
  },

  async created() {
    await this.loadShopProducts(this.locale);
    this.product = await this.getShopProductById(this.$route.params.productId);
  },
  methods: {
    ...mapActions("products", ["loadShopProducts"]),
  },
  computed: {
    ...mapState("products", { products: "shop" }),
    ...mapState("locale", { locale: "locale" }),
    ...mapGetters("products", ["getShopProductById"]),
  },
  watch: {
    async locale() {
      await this.loadShopProducts(this.locale);
      this.product = await this.getShopProductById(
        this.$route.params.productId
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.people {
  &__title {
    padding: 20px 0 50px 0;
    font-size: 48px;
    text-transform: uppercase;
    text-align: center;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1100px) {
  .people {
    text-align: center;
    &__container {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }
  }
}
</style>
