import TestimonialsService from '@/services/Testimonials'

const state = () => ({
  all: []
})

const getters = {}

const actions = {
  loadTestimonials ({ commit }, lang) {
    const testimonials = TestimonialsService.getAll(lang)
    commit('setTestimonials', testimonials)
  }
}

const mutations = {
  setTestimonials (state, testimonials) {
    state.all = testimonials
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
