<template>
  <div>
    <div class="products" v-for="category in products" :key="category.category_id">
      <div class="products__container">      
          <div class="products__box" v-for="product in category.products" :key="product.id">
            <router-link
              :to="{
                name: 'product-detail',
                params: { productId: product.product_id },
              }"
            >
              <img
                class="products__box__image"
                :src="product.img"
                :alt="product.name"
              />
            </router-link>
            <div class="products__box__name">{{ product.name }}</div>
            <div class="products__box__price">
              ${{ formatNumber(product.price) }}
            </div>
            <div class="products__box__add">
              <v-btn @click="add(product)" color="#f75007" dark rounded>
                <v-icon left>
                  mdi-plus
                </v-icon>
                {{ $t("shop.add") }}
              </v-btn>
            </div>
          </div>      
      </div>
    </div>
  </div>  
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formattingMixin } from "@/mixins/formatting.js";

export default {
  name: "ShopProducts",

  mixins: [formattingMixin],

  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },

  created() {
    // Load shop products if not passed as a prop
    if (this.products.length === 0) {
      this.loadShopProducts(this.locale);
    }
  },
  
  methods: {
    ...mapActions("products", ["loadShopProducts"]),
    ...mapActions("cart", ["addToCart", "incrementQuantity"]),

    add(product) {
      const exists = this.items.find(
        (item) => item.product.product_id === product.product_id
      );

      if (!exists) {
        this.addToCart({ product, quantity: 1 });
      } else {
        this.incrementQuantity(product);
      }
    },
  },
  computed: {
    ...mapState("products", { categories: "shop" }),
    ...mapState("locale", { locale: "locale" }),
    ...mapState("cart", { items: "items" }),
  },
  watch: {
    locale() {
      this.loadShopProducts(this.locale);
    },
  },
};
</script>

<style lang="scss" scoped>
.products {
  padding: 25px 25px 50px 25px;
  color: #555555;

  a {
    color: #555555;
    text-decoration: none;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    padding: 20px 0 50px 0;
    font-size: 48px;
    text-transform: uppercase;
    text-align: center;
  }

  &__box {
    margin-left: 15px;
    display: flex;
    flex-direction: column;

    &__name {
      padding-top: 20px;
      font-size: 26px;
      font-weight: 500;
      text-align: center;
    }

    &__price {
      padding-top: 10px;
      font-size: 26px;
      font-weight: 700;
      text-align: center;
    }

    &__add {
      padding-top: 10px;
      text-align: center;
    }

    &__image {
      height: auto;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1300px) {
  .products {
    &__container {
      justify-content: center;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__box {
      margin-bottom: 50px;
      &__name {
        font-size: 18px;
      }
    }

    &__box {
      flex: 0 0 25%;
      max-width: 22%;
    }

    &__button--huge {
      width: initial;
      height: 70px !important;
      font-size: 22px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .products {
    &__container {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__box {
      margin-bottom: 70px;
      &__name {
        font-size: 18px;
      }
    }

    &__box {
      flex: 0 0 25%;
      max-width: 22%;
    }

    &__button--huge {
      width: initial;
      height: 70px !important;
      font-size: 22px;
    }
  }
}
</style>
