<template>
  <div class="product-title">
    {{ title }}
  </div>
</template>

<script>
import { capitalize } from 'lodash'

export default {
  name: 'ProductTitle',

  props: {
    productName: {
      type: String,
      required: true
    }
  },

  computed: {
    title () {
      return capitalize(this.productName)
    }
  }
}
</script>

<style lang="scss" scoped>
.product-title {
  padding: 25px 100px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #12181b;
  font-size: 40px;
  text-transform: capitalize;
}
</style>
