import PeopleService from "@/services/People";

const state = () => ({
  all: []
});

const getters = {};

const actions = {
  async loadPeople({ commit }, lang) {
    const people = await Promise.all(
      PeopleService.getAll(lang).map(async person => {
        const imageModule = await import(`@/assets/images/people/${person.id}-circle.png`);
        person.img = imageModule.default;      
        return person;
      })
    );
    commit("setPeople", people);
  }
};

const mutations = {
  setPeople(state, people) {
    state.all = people;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
