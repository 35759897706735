<template>
  <div class="cart-body">
    <div class="cart-body__item">
      <div class="cart-body__item__image">
        <img :src="item.product.img" />
      </div>
      <div class="cart-body__item__description">
        <div class="cart-body__item__description__title">
          {{ item.product.name }}
        </div>
        <div class="cart-body__item__description__code">
          {{ item.product.code }}
        </div>
        <div class="cart-body__item__description__remove">
          <a @click="removeFromCart(item.product)">{{ $t('cart.remove') }}</a>
        </div>
      </div>
      <div class="cart-body__item__price">
        <div class="cart-body__item__price__counter">
          <v-text-field
            v-model="quantity"
            label=""
            type="number"
            dense
            outlined
          ></v-text-field>
        </div>
        <div class="cart-body__item__price__summary">
          <div class="cart-body__item__price__summary__item">
            <strong>{{ $t('cart.volume') }}:</strong> {{ item.product.points }}
          </div>
          <div class="cart-body__item__price__summary__item">
            <strong>{{ $t('cart.price') }}:</strong> ${{ item.product.price }}
          </div>
          <div class="cart-body__item__price__summary__item">
            <strong>{{ $t('cart.totalPrice') }}:</strong> ${{ itemPrice }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CartItem',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  methods: { ...mapActions('cart', ['addToCart', 'removeFromCart']) },

  computed: {
    itemPrice () {
      return this.item.product.price * this.item.quantity
    },

    quantity: {
      get () {
        return this.item.quantity
      },
      set (value) {
        this.addToCart({
          product: this.item.product,
          quantity: value
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
}

.cart-body {
  padding: 25px 0px;
  display: block;

  &__item {
    display: flex;

    &__image {
      padding-top: 15px;
      img {
        width: 40px;
      }
    }

    &__description {
      padding: 15px;
      text-align: left;

      &__title {
        font-size: 18px;
        font-weight: 700;
      }

      &__code {
        color: #999;
      }

      &__remove {
        a {
          text-decoration: none;
          color: #f75007;
        }
      }
    }

    &__price {
      &__counter {
        padding-top: 15px;
        width: 50%;
      }

      &__summary {
        text-align: right;

        &__item {
          white-space: nowrap;
        }
      }
    }
  }
}

.button-huge {
  height: 50px !important;
  font-size: 24px !important;
}

@media only screen and (min-width: 900px) {
  .cart-body {
    display: none;
  }

  .table-cart-body {
    display: block;
  }
}
</style>
