<template>
  <div>
    <div class="signup-container">
      <FormTitle
        class="signup-container__title"
        :title="$t('signup.shippingMethod')"
      />

      <div class="customer-details">
        <div>
          {{ shipping.method }} - ${{  formatNumber(shipping.cost) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormTitle from "@/components/common/FormTitle.vue";
import { formattingMixin } from "@/mixins/formatting.js";

import { mapState } from "vuex";

export default {
  name: "SignupConfirmShippingMethod",
  components: {
    FormTitle,
  },
  mixins: [formattingMixin],

  computed: {
    ...mapState("signup", { shipping: "shipping" }),
  },
};
</script>

<style lang="scss" scoped>
.strong {
  font-weight: 800;
}

.signup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.customer-details {
  padding: 0 20px 20px 35px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .signup-container {
    max-width: 75%;
  }
}

@media only screen and (min-width: 1300px) {
  .signup-container {
    max-width: 60%;
  }
}
</style>
