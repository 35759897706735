const en = [
  {
    id: "steve",
    img: "@/assets/images/people/steve-circle.png",
    name: "Steve Moretta",
    title: "Founder, President & CEO",
    description:
      "With more than two decades of experience in the industry, Steve founded BESTLIFE with the purpose to bring the highest quality products for better health and the most desirable opportunity for financial freedom."
  },
  {
    id: "johanna",
    img: "@/assets/images/people/johanna-circle.png",
    name: "Johanna Velásquez",
    title: "Chief Operating Officer (COO)",
    description: ""
  },
  {
    id: "jhayvitt",
    img: "@/assets/images/people/jhayvitt-circle.png",
    name: "Jhayvitt Espinoza",
    title: "Corporate Assistant",
    description: ""
  },
  {
    id: "angy",
    img: "@/assets/images/people/angy-circle.png",
    name: "Angy Cervantes",
    title: "Chief Creative Officer (CCO)",
    description: ""
  },  
];

const es = [
  {
    id: "steve",
    img: "@/assets/images/people/steve-circle.png",
    name: "Steve Moretta",
    title: "Fundador, Presidente Y Director Ejecutivo",
    description:
      "Con más de dos décadas de experiencia en la industria, Steve fundó BESTLIFE con el propósito de ofrecer productos de la más alta calidad para una mejor salud y la oportunidad más deseable para la libertad financiera."
  },
  {
    id: "johanna",
    img: "@/assets/images/people/johanna-circle.png",
    name: "Johanna Velásquez",
    title: "Gerente de Operaciones (COO)",
    description: ""
  },
  {
    id: "jhayvitt",
    img: "@/assets/images/people/jhayvitt-circle.png",
    name: "Jhayvitt Espinoza",
    title: "Asistente Corporativa",
    description: ""
  },
  {
    id: "angy",
    img: "@/assets/images/people/angy-circle.png",
    name: "Angy Cervantes",
    title: "Gerente Creativo (CCO)",
    description: ""
  },
];

export default {
  es,
  en
};
