<template>
  <div>
    <Header />
    <MainNav />
    <ShopTitle :title="$t('shop.title')" />
    <ShopFilter v-if="categories" :categories="getAllCategories" @categoryChange="handleCategoryChange" />
    <ShopProducts :products="filteredProducts" />
    <Footer />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import router from "@/router";

import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import MainNav from "@/components/common/MainNav.vue";
import ShopTitle from "@/components/shop/ShopTitle.vue";
import ShopProducts from "@/components/shop/ShopProducts.vue";
import ShopFilter from "@/components/shop/ShopFilter.vue";
import user from "@/mixins/user.js";

export default {
  name: "Shop",
  mixins: [user],
  components: {
    Header,
    Footer,
    MainNav,
    ShopTitle,
    ShopProducts,
    ShopFilter,
  },

  data() {
    return {
      selectedCategories: [],
      categories: [],
      groupedProducts: [],
    };
  },

  created() {
    // Don't allow online shopping unless it is a replicating website
    if (!this.userExists) {
      router.push("/");
    }
    this.loadPeople(this.locale);
    this.loadShopData(); // Fetch shop data
  },

  methods: {
    ...mapActions("people", ["loadPeople"]),
    ...mapActions("products", ["loadShopProducts"]),

    handleCategoryChange(selectedCategories) {
      this.selectedCategories = selectedCategories;
    },

    async loadShopData() {
      await this.loadShopProducts(this.locale);
    },

    getProductsGroupedByCategory(categoryIds) {
    return this.products.reduce((acc, product) => {
      const categoryIndex = acc.findIndex(category => category.category_id === product.category_id);
  
      if (!categoryIds.length || categoryIds.includes(product.category_id)) {
        if (categoryIndex !== -1) {
          acc[categoryIndex].products.push(product);
        } else {
          const newCategory = {
            category_id: product.category_id,
            category: product.category,
            category_es: product.category_es,
            products: [product],
          };
          acc.push(newCategory);
        }
      }
  
      return acc;
    }, []);
  }    
  },

  computed: {
    ...mapState("locale", { locale: "locale" }),
    ...mapState("products", { products: "shop" }),
    ...mapGetters("products", ["getAllCategories"]),

    filteredProducts() {
      return this.getProductsGroupedByCategory(this.selectedCategories);
    },
  },
};
</script>
