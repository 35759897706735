<template>
  <v-card class="mx-auto">
    <v-card-text>
      <v-chip-group 
        v-model="selectedCategories" 
        column 
        multiple
      >
        <!-- Use a v-for loop to dynamically generate chips for each category 
                  :variant="selectedCategories.includes(category.category_id) || true ? 'text' : 'plain'"
        -->
        <v-chip
          v-for="category in categories"
          :key="category.category_id"
          :filter="true"
          :class="selectedCategories.includes(category.category_id) ? 'selected-chip' : 'not-selected-chip'"
          class="filter-chip"
          @click="toggleCategory(category.category_id)"
        >
          {{ category.category }}
        </v-chip>
      </v-chip-group>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedCategories: [], 
  }),
  
  methods: {
    // Function to toggle selected categories
    toggleCategory(categoryId) {
      const index = this.selectedCategories.indexOf(categoryId);
      if (index === -1) {
        this.selectedCategories.push(categoryId);
      } else {
        this.selectedCategories.splice(index, 1);
      }
      this.$emit("categoryChange", this.selectedCategories);
    }
  }
};
</script>

<style scoped>
.filter-chip {
  border: 1px solid rgb(247, 80, 7) !important;
  background-color: #fff !important;
}
.not-selected-chip {
  color: rgb(247, 80, 7) !important;
  /* background-color: rgb(247, 80, 7, 0.05) !important; */

}
.selected-chip {
  color: rgb(247, 80, 7) !important;
  border-color: rgb(247, 80, 7) !important;
  border-width: 1px !important;
  border-style: solid !important;  
  background-color: #fff !important;
}
</style>
