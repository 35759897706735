<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('signup.contactDetails')"
      />

      <div class="form-group">
        <div class="form-group__field">
          <v-text-field
            v-model="phone"
            :label="`${$t('signup.phone')} *`"
            :placeholder="$t('signup.phone')"
            outlined
            :maxlength="10"
            :rules="[rules.required]"
            dense
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="mobile"
            :label="$t('signup.mobile')"
            :placeholder="$t('signup.mobile')"
            outlined
            dense
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="email"
            :label="`${$t('signup.email')} *`"
            :placeholder="$t('signup.email')"
            outlined
            dense
            :rules="[rules.required, rules.email]"
            color="#f75007"
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/mixins/user.js";
import rules from "@/mixins/validation-rules.js";
import FormTitle from "@/components/common/FormTitle.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "FormPersonalDetails",
  mixins: [user, rules],
  components: {
    FormTitle,
  },

  methods: { ...mapActions("signup", ["setUserData"]) },

  computed: {
    ...mapState("signup", { userData: "userData" }),

    phone: {
      get() {
        return this.userData.phone;
      },
      set(value) {
        this.setUserData({ key: "phone", value });
      },
    },

    mobile: {
      get() {
        return this.userData.mobile;
      },
      set(value) {
        this.setUserData({ key: "mobile", value });
      },
    },

    email: {
      get() {
        return this.userData.email;
      },
      set(value) {
        this.setUserData({ key: "email", value });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.form-group {
  padding: 0 20px;
}

.sponsor-details {
  padding: 0 20px 20px 35px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;

    &__field {
      width: 50%;
      padding: 5px 20px 5px 5px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 70%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: start;

    &__field {
      width: 30%;
      max-width: 30%;
      padding: 5px 15px;
    }
  }
}
</style>
