<template>
  <div class="table-containeer">
    <table class="cart-body-table">
      <tr>
        <th></th>
        <th>{{ $t('cart.product') }}</th>
        <th>{{ $t('cart.quantity') }}</th>
        <th>{{ $t('cart.volume') }}</th>
        <th>{{ $t('cart.price') }}</th>
      </tr>
      <CartTableRow v-for="item in items" :item="item" :key="item.product_id" />
    </table>
  </div>
</template>

<script>
import CartTableRow from '@/components/cart/CartTableRow.vue'

export default {
  name: 'CartTable',
  components: { CartTableRow },

  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-body {
  padding: 25px 20px;
  display: block;

  &__item {
    display: flex;

    &__image {
      padding-top: 15px;
      img {
        width: 40px;
      }
    }

    &__description {
      padding: 15px;
      text-align: left;

      &__title {
        font-size: 18px;
        font-weight: 700;
      }

      &__code {
        color: #999;
      }

      &__remove {
        a {
          text-decoration: none;
          color: #f75007;
        }
      }
    }

    &__price {
      &__counter {
        padding-top: 15px;
        // width: 50%;
      }

      &__summary {
        text-align: right;
      }
    }
  }
}

.cart-body-table {
  // width: 80%;

  font-size: 20px;
  margin-top: 30px;
  display: none;

  &__image {
    width: 10%;
    img {
      width: 70px;
    }
  }

  td {
    vertical-align: top;
    padding: 20px 40px;
  }

  &__description {
    width: 35%;
    text-align: left;
  }

  &__quantity {
    padding: 20px 10px !important;
    width: 10%;
  }

  &__volume {
    width: 15%;
    text-align: center;
  }
  &__price {
    text-align: right;
    width: 30%;
  }
}

@media only screen and (min-width: 900px) {
  .cart-body-table {
    width: 80%;
    font-size: 20px;
    margin-top: 30px;
    display: block;
  }

  table {
    margin-left: auto;
    margin-right: auto;
    // width: 100% !important;
    // min-width: 900px !important;
  }
  td {
    border-bottom: 1px solid #ccc;
  }
}
</style>
