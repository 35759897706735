import troyHamburgImage from '@/assets/images/homepage/testimonials-troy-hamburg.jpeg';
import taniaLeanosImage from '@/assets/images/homepage/testimonial-tania-leanos.jpg';
import christianCheneImage from '@/assets/images/homepage/testimonial-christian-chene.jpg';
import jackMaggieImage from '@/assets/images/homepage/testimonial-jack-maggie.jpg';


const en = [
  {
    id: 1,
    text:
      'Me, T-ball, baseball, soccer, division 1 competition, broken collar bones, jammed fingers, sprains, military, surgeries, injuries, recovery, now boxing and smarter tried BESTLIFE and there is nothing better than this!',
    img: troyHamburgImage,
    name: 'Troy Hamburg',
    location: 'Texas'
  },
  {
    id: 2,
    text:
      'I was introduced to these products a few years ago and I have not stopped taking them since. Growing up I’ve always had problems with my digestive system and after taking the power cleanse I’ve noticed a tremendous impact on my health. It has been the best detox product I’ve ever used!',
    img: taniaLeanosImage,
    name: 'Tania Leanos',
    location: 'Illinois'
  },
  {
    id: 3,
    text:
      'Since I tried the products for the first time, I felt extremely well. I felt more energetic and my stamina and strength have improved drastically at the gym. Also I am more focused and attentive on my daily activities and I am thriving in my business for this reason!',
    img: christianCheneImage,
    name: 'Christian Chene',
    location: 'Texas'
  },
  {
    id: 4,
    text:
      'In BestLife, we found a real opportunity for both our health and our income. We have experienced amazing results with the products and we know we can help many people sharing the message of health and economic prosperity. BESTLIFE is simply the best!',
    img: jackMaggieImage,
    name: 'Jack and Maggie Thorne',
    location: 'Florida'
  }
]

const es = [
  {
    id: 1,
    text:
      'Atravesé por todo: T-ball, béisbol, fútbol, ​​competencia de división 1, clavículas rotas, dedos atascados, esguinces, el ejército, cirugías, lesiones y ahora boxeo y levanto pesas. He probado innumerables suplementos. ¡Probé BESTLIFE y no hay nada mejor que esto!',
    img: troyHamburgImage,
    name: 'Troy Hamburg',
    location: 'Texas'
  },
  {
    id: 2,
    text:
      'Probé estos productos hace algunos años y no he dejado de usarlos desde entonces. Desde temprana edad he tenido problemas con mi sistema digestivo pero desde que comencé usando POWERCLEANSE noté un tremendo impacto en mi salud. ¡Lo mejor que me ha pasado es BESTLIFE',
    img: taniaLeanosImage,
    name: 'Tania Leanos',
    location: 'Illinois'
  },
  {
    id: 3,
    text:
      'Desde que probé los productos por primera vez, me sentí muy bien. Me sentí con más energía y mi resistencia y fuerza han mejorado drásticamente en el gimnasio. Además, estoy más concentrado y atento a mis actividades diarias y ¡estoy prosperando en mi negocio por esta razón!',
    img: christianCheneImage,
    name: 'Christian Chene',
    location: 'Texas'
  },
  {
    id: 4,
    text:
      'En BestLife, encontramos una oportunidad real tanto para nuestra salud como para nuestros ingresos. Hemos experimentado resultados asombrosos con los productos y sabemos que podemos ayudar a muchas personas a compartir el mensaje de salud y prosperidad económica. ¡BESTLIFE es simplemente lo mejor!',
    img: jackMaggieImage,
    name: 'Jack y Maggie Thorne',
    location: 'Florida'
  }
]

export default {
  en,
  es
}
