const en = [
  {
    id: 'immune-advance',
    img: () => import('@/assets/images/products/products-immune-advance.jpg'),
    name: 'IMMUNE ADVANCE',
    description: [
      'Did you know that you are exposed to, approximately, 300,000 germs by just touching your cell phone?',
      'Your immune system is your primary line of defense against such harmful pathogens. Without a powerful immune system, you will continuously get sick from bacterial and viral infections!',
      'Immune Advance, with its combination of vitamins and fundamental nutrients, is the leading supplement to boost your immune system. While it cleanses your body from the disease-causing germs, it strengthens your system to defeat any imminent illness.'
    ],
    properties: {
      listItems: [
        'Help strengthen your immune system by balancing your immune response with  Vitamin D *',
        'Immune Advance Acts Like a Multivitamin for Your Immune System',
        'Supports mucosal defense*',
        'Excellent source of zinc, Vitamins C and D',
        'Made with non-GM ingredients *',
        'Provide general antioxidant support against free radicals *'
      ],
      disclaimer:
        'These statements have not been evaluated by the FDA. This product is not intended to diagnose, treat, cure or prevent disease.'
    }
  },
  {
    id: 'nutriblend-vanilla',
    // img: require('@/assets/images/products/products-nutriblend-vanilla.jpg'),
    name: 'NUTRI BLEND Vanilla',
    description: [
      'Nutri Blend contains a blend of antioxidants, fruits (Acaí, Mangosteen and Moringa) along with Ganoderma mushrooms.',
      'Contains BCAA that are considered to be the perfect complement for people who are looking to gain muscle mass but even more for people who are looking to reduce the fat but keeping as much muscle as possible.',
      'High source (22g) of quality protein to help support weight management in conjunctionwith a healthy diet and exercise. *',
      'Contains essential nutrients including 18 vitamins and minerals. *',
      'It is a perfect solution for people who want a delicious and nutritious meal on the go. *',
      'Our meal replacement gives you all the benefits of a healthy meal. *'
    ],
    properties: {
      listItems: [
        'REPLACEMENT OF A COMPLETE MEAL ',
        '160 Calories',
        '22 grams of protein',
        '<1 GR of Sugar',
        'It contains: Acai, Mangosteen, Ganoderma, Moringa and BCAA (Amino Acids for muscle building and muscle recovery) '
      ],
      disclaimer:
        '*These statements have not been evaluated by the FDA. This product is not intended to diagnose, treat, cure or prevent disease.'
    }
  },
  {
    id: 'nutriblend-chocolate',
    // img: require('@/assets/images/products/products-nutriblend-chocolate.jpg'),
    name: 'NUTRI BLEND Coffee - Chocolate',
    description: [
      'Nutri Blend contains a blend of antioxidants, fruits (Acaí, Mangosteen and Moringa) along with Ganoderma mushrooms.',
      'Contains BCAA that are considered to be the perfect complement for people who are looking to gain muscle mass but even more for people who are looking to reduce the fat but keeping as much muscle as possible.',
      'High source (22g) of quality protein to help support weight management in conjunctionwith a healthy diet and exercise. *',
      'Contains essential nutrients including 18 vitamins and minerals. *',
      'It is a perfect solution for people who want a delicious and nutritious meal on the go. *',
      'Our meal replacement gives you all the benefits of a healthy meal. *'
    ],
    properties: {
      listItems: [
        'REPLACEMENT OF A COMPLETE MEAL ',
        '160 Calories',
        '22 grams of protein',
        '<1 GR of Sugar',
        'It contains: Acai, Mangosteen, Ganoderma, Moringa and BCAA (Amino Acids for muscle building and muscle recovery) '
      ],
      disclaimer:
        '*These statements have not been evaluated by the FDA. This product is not intended to diagnose, treat, cure or prevent disease.'
    }
  },
  {
    id: 'cardiovida',
    // img: require('@/assets/images/products/products-cardiovida.jpg'),
    name: 'CARDIO VIDA',
    description: [
      'CardioVida contains Free Form Arginine and vitamin D and is specially formulated and approved by doctors.',
      'This special blend of semi-essential amino acids (L-Arginine, L-Citrulline, vitamins, minerals, fulvic minerals, grape seed extract, fiber, and probiotics) helps promote a healthy cardiovascular system. This powerful combination of ingredients is sure to revolutionize the nutraceutical industry. *',
      'Arginine has long been a subject of clinical research, and its health benefits have been well documented. The Nobel Prize in medicine was awarded to three scientists in 1998 for discovering nitric oxide’s role as a vasodilator. The role of Arginine is essential for the enzymatic reaction in endothelial cells that is responsible for the creation of nitric oxide in the body. *',
      'There are over 85,000 published clinical studies showing that Arginine could help lower cholesterol and triglycerides, delay onset of diabetes, enhance sexual function, help with congestive heart failure, speed tissue regeneration and healing, increase production of human growth hormone (HGH), enhance muscle growth, reduce blood clots and strokes, and more. *',
      'CardioVidaTM focuses on the four key indicators of heart health: cholesterol, triglycerides, homocysteine, and oxidative stress.'
    ],
    properties: {
      listItems: [
        'Supports healthy circulation and healthy blood vessels *',
        'Maintains healthy homocysteine levels already within normal range*',
        'Antioxidant Protection*',
        'Increases blood flow to the heart*',
        'Keeps blood vessels toned, flexible, and youthful for improved circulation*',
        'Supports healthy levels of blood pressure that are already within a normal range*',
        'Provides essential vitamins, minerals, and fiber for proper functioning of the human body*',
        'It helps your body produce nitric oxide (NO) to improve the functions of the circulatory, immune, and nervous systems. L-arginine and L- Citrulline trigger cells to produce and recycle more nitric oxide.*'
      ],
      disclaimer:
        '* These statements have not been evaluated by the FDA. This product is not intended to diagnose, treat, cure or prevent disease.'
    }
  },
  {
    id: 'powercleanse',
    // img: require('@/assets/images/products/products-powercleanse.jpg'),
    name: 'POWER CLEANSE',
    description: [
      'PowerCleanse TM is formulated to support the digestive system, relieves constipation, promotes the elimination of wastes and toxins, and promotes weight loss. It is much more than the average fiber product. PowerCleanse TM contains probiotics with a mixture of herbs, which help promotes health and overall wellness. It is designed to expel accumulated waste while providing the nutrients needed to promote a healthy balanced digestive system.',
      'Cleansing the body of toxins is not a new concept, though, perhaps we forget the importance of doing a routine cleanse. Cleansing the system of waste could help eliminate unnecessary pounds, increase the absorption of nutrients, and supports overall wellness. Dietary supplements are useless if the body cannot absorb them properly.'
    ],
    properties: {
      listItems: [
        'Increases the absorption of nutrients *',
        'Promotes weight loss *',
        'Promotes colon health and regularity while cleansing and detoxification *',
        'Supports the growth of intestinal flora *',
        'Helps to eliminate intestinal parasites *'
      ],
      disclaimer:
        '* These statements have not been evaluated by the FDA. This product is not intended to diagnose, treat, cure or prevent disease.'
    }
  },
  {
    id: 'bl-pre-workout',
    // img: require('@/assets/images/products/products-bl-pre-workout.jpg'),
    name: 'BL PRE-WORKOUT',
    description: [
      'PUMP, STAMINA, STRENGTH, NOOTROPIC',
      'Need that extra pump for your next sweat session? Boost your energy, enhance your endurance, retrieve your focus, and maximize your gains with BL Pre-Workout.',
      'Contains:',
      '- 500 mg Beet Root Extract',
      '- 1,500 mg L-Arginine',
      '- 600 mg Lions Mane Mushroom',
      '- 300mg Natural Caffeine from Green Tea'
    ],
    properties: {
      listItems: [
        'Our unique blend of amino acids, superfoods, botanicals, boosters, and electrolytes *',
        'Improves Cognitive Function, Promotes Muscle Growth, Stimulates Fat Burn, Increases Strength *',
        'Strengthens immune system *',
        'About Lions Mane Mushroom: Studies show that Lions Mane  decreases body inflammation; reduces fatigue after workout sessions; enhances energy levels; facilitates recovery; accelerates fat metabolism; improves concentration and focus while exercising '
      ],
      disclaimer:
        '* These statements have not been evaluated by the FDA. This product is not intended to diagnose, treat, cure or prevent disease.'
    }
  },
  {
    id: 'bl-post-workout',
    // img: require('@/assets/images/products/products-bl-post-workout.jpg'),
    name: 'BL POST-WORKOUT',
    description: [
      'MUSCLE GROWTH, RECOVERY, HYDRATION',
      'Need that extra pump for your next sweat session? Boost your energy, enhance your endurance, retrieve your focus, and maximize your gains with BL Pre-Workout.',
      'Contains:',
      '- 500 mg Beet Root Extract',
      '- 500 mg Ginger Extract'
    ],
    properties: {
      listItems: [
        'Our unique blend of amino acids, superfoods, botanicals and electrolytes:',
        'Supports Muscle Growth, Facilitates Recovery, Replenishes, Electrolytes'
      ],
      disclaimer:
        '* These statements have not been evaluated by the FDA. This product is not intended to diagnose, treat, cure or prevent disease.'
    }
  }
]

const es = [
  {
    id: 'immune-advance',
    // img: require('@/assets/images/products/products-immune-advance.jpg'),
    name: 'IMMUNE ADVANCE',
    description: [
      '¿Sabías que estás expuesto a, aproximadamente, 300.000 gérmenes con solo tocar tu teléfono celular?',
      'Su sistema inmunológico es su principal línea de defensa contra estos patógenos dañinos. Sin un sistema inmunológico poderoso, ¡continuamente se enfermará de infecciones bacterianas y virales!',
      'Immune Advance, con su combinación de vitaminas y nutrientes fundamentales, es el suplemento líder para estimular su sistema inmunológico; mientras limpia su cuerpo de los gérmenes que causan enfermedades, fortalece su sistema para vencer cualquier enfermedad inminente.'
    ],
    properties: {
      listItems: [
        'Ayuda a fortalecer su sistema inmunológico equilibrando su respuesta inmunológica con vitamina D *',
        'Immune Advance actúa como un multivitamínico para su sistema inmunológico',
        'Apoya la defensa de la mucosa *',
        'Excelente fuente de zinc, vitaminas C y D',
        'Elaborado con ingredientes no transgénicos *',
        'Brindan apoyo antioxidante general contra los radicales libres *'
      ],
      disclaimer:
        'Estas declaraciones no han sido evaluadas por la FDA. Este producto no está destinado a diagnosticar, tratar, curar o prevenir enfermedades.'
    }
  },
  {
    id: 'nutriblend-vanilla',
    // img: require('@/assets/images/products/products-nutriblend-vanilla.jpg'),
    name: 'NUTRI BLEND Vainilla',
    description: [
      'Nutri Blend contiene una mezcla de antioxidantes, frutas (Acaí, Mangostán y Moringa) junto con hongos Ganoderma.',
      'Contiene BCAA que se consideran el complemento perfecto para las personas que buscan ganar masa muscular pero aún más para las personas que buscan reducir la grasa pero manteniendo la mayor cantidad de músculo posible. *',
      'Fuente alta (22 g) de proteína de calidad para ayudar a apoyar el control de peso junto con una dieta saludable y ejercicio. *',
      'Contiene nutrientes esenciales que incluyen 18 vitaminas y minerales. *',
      'Es una solución perfecta para las personas que desean una comida deliciosa y nutritiva para llevar. *',
      'Nuestro sustituto de comidas le brinda todos los beneficios de una comida saludable. *'
    ],
    properties: {
      listItems: [
        'Sustituye una comida completa *',
        '160 calorías',
        '22 gramos de proteína',
        '<1 GR de azúcar',
        'Contiene: Acai, Mangostán, Ganoderma, Moringa y BCAA (Aminoácidos para la construcción y recuperación muscular)'
      ],
      disclaimer:
        '* Estas declaraciones no han sido evaluadas por la FDA. Este producto no está destinado a diagnosticar, tratar, curar o prevenir enfermedades.'
    }
  },
  {
    id: 'nutriblend-chocolate',
    // img: require('@/assets/images/products/products-nutriblend-chocolate.jpg'),
    name: 'NUTRI BLEND Chocolate Bombón',
    description: [
      'Nutri Blend contiene una mezcla de antioxidantes, frutas (Acaí, Mangostán y Moringa) junto con hongos Ganoderma.',
      'Contiene BCAA que se consideran el complemento perfecto para las personas que buscan ganar masa muscular pero aún más para las personas que buscan reducir la grasa pero manteniendo la mayor cantidad de músculo posible. *',
      'Fuente alta (22 g) de proteína de calidad para ayudar a apoyar el control de peso junto con una dieta saludable y ejercicio. *',
      'Contiene nutrientes esenciales que incluyen 18 vitaminas y minerales. *',
      'Es una solución perfecta para las personas que desean una comida deliciosa y nutritiva para llevar. *',
      'Nuestro sustituto de comidas le brinda todos los beneficios de una comida saludable. *'
    ],
    properties: {
      listItems: [
        'Sustituye una comida completa *',
        '160 calorías',
        '22 gramos de proteína',
        '<1 GR de azúcar',
        'Contiene: Acai, Mangostán, Ganoderma, Moringa y BCAA (Aminoácidos para la construcción y recuperación muscular)'
      ],
      disclaimer:
        '* Estas declaraciones no han sido evaluadas por la FDA. Este producto no está destinado a diagnosticar, tratar, curar o prevenir enfermedades.'
    }
  },
  {
    id: 'cardiovida',
    // img: require('@/assets/images/products/products-cardiovida.jpg'),
    name: 'CARDIO VIDA',
    description: [
      'CardioVida contiene arginina de forma libre y vitamina D y está especialmente formulado y aprobado por médicos. *',
      'Esta mezcla especial de aminoácidos semiesenciales (L-Arginina, L-Citrulina, vitaminas, minerales, minerales fúlvicos, extracto de semilla de uva, fibra y probióticos) ayuda a promover un sistema cardiovascular saludable. Esta poderosa combinación de ingredientes seguramente revolucionará la industria nutracéutica. *',
      'La L-arginina ha sido objeto de investigación clínica durante mucho tiempo y sus beneficios para la salud están bien documentados. El Premio Nobel de Medicina fue otorgado a tres científicos en 1998 por descubrir el papel del óxido nítrico como vasodilatador. El papel de la arginina es esencial para la reacción enzimática en las células endoteliales que es responsable de la creación de óxido nítrico en el cuerpo. *',
      'Hay más de 100,000 estudios clínicos publicados que muestran que la arginina podría ayudar a reducir el colesterol y los triglicéridos, retrasar la aparición de la diabetes, mejorar la función sexual, ayudar con la insuficiencia cardíaca congestiva, acelerar la regeneración y curación de los tejidos, aumentar la producción de la hormona del crecimiento humano (HGH), mejorar los músculos crecimiento, reducir los coágulos de sangre y los accidentes cerebrovasculares, y más. *',
      'CardioVida se centra en los cuatro indicadores clave de la salud del corazón: colesterol, triglicéridos, homocisteína y estrés oxidativo.'
    ],
    properties: {
      listItems: [
        'Apoya la circulación saludable y los vasos sanguíneos saludables *',
        'Mantiene niveles saludables de homocisteína que ya se encuentran dentro del rango normal *',
        'Protección antioxidante *',
        'Aumenta el flujo sanguíneo al corazón *',
        'Mantiene los vasos sanguíneos tonificados, flexibles y juveniles para mejorar la circulación *',
        'Apoya niveles saludables de presión arterial que ya se encuentran dentro de un rango normal *',
        'Aporta vitaminas, minerales y fibra esenciales para el correcto funcionamiento del cuerpo humano *',
        'Ayuda a su cuerpo a producir óxido nítrico (NO) para mejorar las funciones del sistema circulatorio, inmunológico y nervioso. La L-arginina y la L-citrulina desencadenan que las células produzcan y reciclen más óxido nítrico. *'
      ],
      disclaimer:
        '* Estas declaraciones no han sido evaluadas por la FDA. Este producto no está destinado a diagnosticar, tratar, curar o prevenir enfermedades.'
    }
  },
  {
    id: 'powercleanse',
    // img: require('@/assets/images/products/products-powercleanse.jpg'),
    name: 'POWER CLEANSE',
    description: [
      'PowerCleanse  está formulado para apoyar el sistema digestivo, alivia el estreñimiento, promueve la eliminación de desechos y toxinas y promueve la pérdida de peso. *',
      'Es mucho más que el producto de fibra promedio. PowerCleanse TM contiene probióticos con una mezcla de hierbas, que ayudan a promover la salud y el bienestar general. Está diseñado para expulsar los desechos acumulados mientras proporciona los nutrientes necesarios para promover un sistema digestivo saludable y equilibrado. *',
      'Limpiar el cuerpo de toxinas no es un concepto nuevo, aunque quizás nos olvidemos de la importancia de hacer una limpieza de rutina. Limpiar el sistema de desechos podría ayudar a eliminar kilos innecesarios, aumentar la absorción de nutrientes y respaldar el bienestar general. Los suplementos dietéticos son inútiles si el cuerpo no puede absorberlos adecuadamente. *'
    ],
    properties: {
      listItems: [
        'Aumenta la absorción de nutrientes *',
        'Promueve la pérdida de peso *',
        'Promueve la salud y regularidad del colon mientras limpia y desintoxica *',
        'Apoya el crecimiento de la flora intestinal *',
        'Ayuda a eliminar los parásitos intestinales *'
      ],
      disclaimer:
        '* Estas declaraciones no han sido evaluadas por la FDA. Este producto no está destinado a diagnosticar, tratar, curar o prevenir enfermedades.'
    }
  },
  {
    id: 'bl-pre-workout',
    // img: require('@/assets/images/products/products-bl-pre-workout.jpg'),
    name: 'BL PRE-WORKOUT',
    description: [
      'GANANCIA DE MUSCULO, RESISTENCIA, FUERZA, NOOTROPICO',
      '¿Necesitas ese impulso extra para tu próxima sesión de ejercicios ? Aumenta tu energía, mejora tu resistencia, recupera tu enfoque y maximiza tus ganancias de músculo con BL Pre - Workout.',
      'Contiene:',
      '- Extracto de raíz de remolacha 500 mg',
      '- 1,500 mg de L-Arginina',
      '- 600 mg de hongo Lion’s Mane',
      '- 300 mg de cafeína natural de té verde'
    ],
    properties: {
      listItems: [
        'Nuestra mezcla única de aminoácidos, superalimentos, botánicos, estimulantes y electrolitos *',
        'Mejora la función cognitiva, promueve el crecimiento muscular, estimula la quema de grasa, aumenta la fuerza *',
        'Fortalece el sistema inmunológico *',
        'Acerca del hongo Lion’s Mane: Los estudios demuestran que Lions Mane disminuye la inflamación corporal; reduce la fatiga después de las sesiones de entrenamiento; mejora los niveles de energía; facilita la recuperación; acelera el metabolismo de las grasas; mejora la concentración y el enfoque durante el ejercicio*'
      ],
      disclaimer:
        '* Estas declaraciones no han sido evaluadas por la FDA. Este producto no está destinado a diagnosticar, tratar, curar o prevenir enfermedades.'
    }
  },
  {
    id: 'bl-post-workout',
    // img: require('@/assets/images/products/products-bl-post-workout.jpg'),
    name: 'BL POST-WORKOUT',
    description: [
      'DESARROLLO MUSCULAR, RECUPERACIÓN, HIDRATACIÓN',
      '¿Necesitas recuperarte después de esa sesión de ejercicios? Repón tu cuerpo y aumenta tu fuerza muscular con BL Post-Workout.',
      'Contiene:',
      '- Extracto de raíz de remolacha 500 mg',
      '- 500 mg de extracto de jengibre'
    ],
    properties: {
      listItems: [
        'Nuestra mezcla única de aminoácidos, superalimentos, botánicos y electrolitos:  apoya el crecimiento muscular, facilita la recuperación, reabastece, electrolitos'
      ],
      disclaimer:
        'Estas declaraciones no han sido evaluadas por la FDA. Este producto no está destinado a diagnosticar, tratar, curar o prevenir enfermedades.'
    }
  }
]

export default {
  es,
  en
}
