import { render, staticRenderFns } from "./ConfirmShippingAddress.vue?vue&type=template&id=312e31c6&scoped=true&"
import script from "./ConfirmShippingAddress.vue?vue&type=script&lang=js&"
export * from "./ConfirmShippingAddress.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmShippingAddress.vue?vue&type=style&index=0&id=312e31c6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "312e31c6",
  null
  
)

export default component.exports