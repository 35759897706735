<template>
  <div>
    <Header />
    <MainNav />
    <ImageSlider />
    <Navigation />
    <Products />
    <Testimonials />
    <SignupBanner />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import MainNav from "@/components/common/MainNav.vue";
import Testimonials from "@/components/homepage/Testimonials.vue";
import SignupBanner from "@/components/homepage/SignupBanner.vue";
import Products from "@/components/homepage/Products.vue";
import ImageSlider from "@/components/homepage/ImageSlider.vue";
import Navigation from "@/components/homepage/Navigation.vue";
import user from "@/mixins/user.js";

export default {
  name: "Homepage",
  mixins: [user],
  components: {
    Header,
    Footer,
    Testimonials,
    SignupBanner,
    Products,
    ImageSlider,
    Navigation,
    MainNav,
  },
};
</script>

<style></style>
