<template>
  <div class="products">
    <div class="products__container">
      <div class="products__container__image">
        <img :src="product.img" :alt="product.name" />
      </div>

      <div class="products__box">
        <div class="products__box__col1">
          <div class="products__box__name">{{ product.name }}</div>
          <div class="products__box__code">{{ product.code }}</div>
          <div class="products__box__price">
            ${{ formatNumber(product.price) }}
          </div>
          <div class="products__box__volume">
            {{ $t('shop.volume') }}: {{ product.points }}
          </div>
          <div class="products__box__add">
            <div class="products__box__add__counter">
              <v-text-field
                v-model="quantity"
                label=""
                type="number"
                dense
                outlined
              ></v-text-field>
            </div>
            <div class="products__box__add__button">
              <v-btn @click="add(product)" color="#f75007" dark rounded>
                {{ $t('shop.addToCart') }}
              </v-btn>
            </div>
          </div>
        </div>
        <div class="products__box__col2">
          <div class="products__box__description">
            <div>{{ $t('shop.description') }}</div>
            <p>{{ product.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { formattingMixin } from "@/mixins/formatting.js";

export default {
  name: 'ShopProductDetail',

  mixins: [formattingMixin],

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      quantity: 1
    }
  },

  methods: {
    ...mapActions('cart', ['addToCart']),
    add (product) {
      this.addToCart({ product, quantity: this.quantity })
    }
  },

  computed: {
    ...mapState('cart', { items: 'items' })
  }
}
</script>

<style lang="scss" scoped>
.products {
  padding: 25px 25px 50px 25px;
  color: #555555;

  a {
    color: #555555;
    text-decoration: none;
  }

  &__container {
    display: flex;
    flex-direction: column;

    &__image {
      max-width: 500px;

      img {
        height: auto;
        width: 100%;
      }
    }
  }

  &__title {
    padding: 20px 0 50px 0;
    font-size: 48px;
    text-transform: uppercase;
    text-align: center;
  }

  &__box {
    margin-left: 15px;
    display: flex;
    flex-direction: column;

    &__name {
      padding-top: 20px;
      font-size: 26px;
      font-weight: 700;
    }

    &__price {
      padding-top: 10px;
      font-size: 26px;
      font-weight: 700;
    }

    &__code {
      font-size: 20px;
      color: #777;
    }

    &__volume {
      padding-top: 5px;
      font-size: 18px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ccc;
    }

    &__add {
      display: flex;
      padding-top: 20px;
      text-align: center;

      &__counter {
        padding-right: 10px;
        flex-grow: 1;
        max-width: 100px;
      }

      &__button {
        flex-grow: 2;
        text-align: left;
      }
    }

    &__description {
      border: 1px solid #ccc;
      padding: 15px;

      div {
        font-weight: 700;
        font-size: 24px;
      }

      p {
        margin-top: 10px;
      }
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1000px) {
  .products {
    &__container {
      &__image {
        max-width: initial;
        text-align: center;

        img {
          max-width: 500px;
        }
      }
    }

    &__box {
      flex-direction: row;
      width: 100%;

      &__col1 {
        flex-grow: 1;
        flex-basis: 0;
        padding: 0 15px;
      }

      &__col2 {
        flex-grow: 1;
        flex-basis: 0;
        padding: 0 25px;
      }

      &__description {
        p {
          margin-top: 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .products {
    &__container {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__image {
      img {
        max-width: initial;
      }
    }

    &__box {
      flex: 0 0 25%;
      max-width: 22%;
      min-width: 370px;

      &__name {
        font-size: 18px;
      }

      &__col1 {
        padding: 0 15px;
      }

      &__col2 {
        padding: 0 15px;
      }
    }
  }
}
</style>
