<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('checkout.orderDetails')"
      />

      <div class="mobile-item-container">
        <ConfirmOrderItem
          v-for="item in items"
          :item="item"
          :key="item.product_id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormTitle from "@/components/common/FormTitle.vue";
import ConfirmOrderItem from "@/components/checkout/ConfirmOrderItem.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "ConfirmOrderDetail",
  components: {
    FormTitle,
    ConfirmOrderItem,
  },

  methods: { ...mapActions("cart", ["setCustomerData"]) },

  computed: {
    ...mapState("cart", {
      customer: "customer",
      options: "options",
      items: "items",
    }),
  },
};
</script>

<style lang="scss" scoped>
.strong {
  font-weight: 800;
}

.mobile-item-container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.customer-details {
  padding: 0 20px 20px 35px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 60%;
  }
}
</style>
