const state = () => ({
  locale: "en"
});

const getters = {};

const actions = {
  setLocale({ commit }, locale) {
    commit("setLocale", locale);
  }
};

const mutations = {
  setLocale(state, locale) {
    state.locale = locale;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
