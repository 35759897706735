<template>
  <div v-if="product">
    <Header />
    <MainNav />
    <ProductTitle :productName="product.name" />
    <ProductDescription :product="product" />
    <ProductProperties :product="product" />
    <ProductLabel :product="product" />
    <Footer />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import MainNav from "@/components/common/MainNav.vue";
import ProductTitle from "@/components/product/ProductTitle.vue";
import ProductDescription from "@/components/product/ProductDescription.vue";
import ProductProperties from "@/components/product/ProductProperties.vue";
import ProductLabel from "@/components/product/ProductLabel.vue";
import user from "@/mixins/user.js";

export default {
  name: "Product",
  mixins: [user],
  components: {
    Header,
    Footer,
    MainNav,
    ProductTitle,
    ProductDescription,
    ProductProperties,
    ProductLabel,
  },
  data() {
    return {
      product: null,
    };
  },

  created() {
    this.loadProducts(this.locale);
    this.product = this.getProductById(this.$route.params.productId);
  },
  methods: { ...mapActions("products", ["loadProducts"]) },
  computed: {
    ...mapState("products", { products: "all" }),
    ...mapState("locale", { locale: "locale" }),
    ...mapGetters("products", ["getProductById"]),
  },
  watch: {
    locale() {
      this.loadProducts(this.locale);
      this.product = this.getProductById(this.$route.params.productId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
