import products from "@/data/products";
import Api from "./Api";

const blwApi = process.env.VUE_APP_BLW_API;

class ProductsService {
  getAll(lang) {
    return products[lang];
  }

  async getShopProducts() {
    const response = await Api().get(`${blwApi}/shop/getProducts/`);
    return this._handleResponse(response);
  }

  async getSignupProducts() {
    const response = await Api().get(`${blwApi}/signup/getProducts/`);
    return this._handleResponse(response);
  }

  _handleResponse(response) {
    try {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Could not fetch products");
      }
    } catch (e) {
      console.warn(e);
    }
  }
}

export default new ProductsService();
