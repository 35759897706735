<template>
  <div>
    <Header />
    <MainNav />
    <PageTitle :title="$t('signup.enrollmentConfirmation')" />

    <ConfirmSponsor />
    <ConfirmPersonalDetails />
    <ConfirmContactDetails />
    <ConfirmBillingAddress />
    <ConfirmShippingAddress />
    <ConfirmSiteURL />
    <ConfirmProductSelection />

    <ConfirmShippingMethod v-if="shipping.cost" />
    <ConfirmPaymentMethod />
    <ConfirmOrderSummary />

    <div class="container mt-5" v-if="errors.length > 0">
      <div class="container__notice">
        <Notice :message="errors" type="error" />
      </div>
    </div>

    <div class="container">
      <div class="checkout-buttons">
        <div class="checkout-buttons__button" v-if="!isProcessing">
          <v-btn class="button-huge" color="#f75007" dark rounded to="/signup">
            {{ $t("signup.makeChanges") }}
          </v-btn>
        </div>

        <div class="checkout-buttons__button" v-if="!isProcessing">
          <v-btn @click="submitOrder" class="button-huge" color="" dark rounded>
            {{ $t("signup.completeEnrollment") }}
          </v-btn>
        </div>

        <div v-if="isProcessing" class="checkout-buttons__spinner">
          <v-progress-circular
            :width="3"
            size="60"
            color="#f75007"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import MainNav from "@/components/common/MainNav.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import ConfirmPersonalDetails from "@/components/signup/ConfirmPersonalDetails.vue";
import ConfirmContactDetails from "@/components/signup/ConfirmContactDetails.vue";
import ConfirmBillingAddress from "@/components/signup/ConfirmBillingAddress.vue";
import ConfirmShippingAddress from "@/components/signup/ConfirmShippingAddress.vue";
import ConfirmSiteURL from "@/components/signup/ConfirmSiteURL.vue";
import ConfirmProductSelection from "@/components/signup/ConfirmProductSelection.vue";
import ConfirmShippingMethod from "@/components/signup/ConfirmShippingMethod.vue";
import ConfirmPaymentMethod from "@/components/checkout/ConfirmPaymentMethod.vue";
import ConfirmOrderSummary from "@/components/signup/ConfirmOrderSummary.vue";
import ConfirmSponsor from "@/components/signup/ConfirmSponsor.vue";
import Notice from "@/components/common/Notice.vue";

import { mapActions, mapState, mapGetters } from "vuex";
import user from "@/mixins/user.js";

export default {
  name: "SignupConfirm",
  mixins: [user],
  components: {
    Header,
    Footer,
    MainNav,
    PageTitle,
    ConfirmPersonalDetails,
    ConfirmContactDetails,
    ConfirmBillingAddress,
    ConfirmShippingAddress,
    ConfirmShippingMethod,
    ConfirmPaymentMethod,
    ConfirmOrderSummary,
    ConfirmSiteURL,
    ConfirmSponsor,
    ConfirmProductSelection,
    Notice,
  },

  data() {
    return {
      isProcessing: false,
    };
  },

  async created() {
    await this.validate();

    if (this.errors.length > 0) {
      this.$router.push("/signup");
    }
  },

  methods: {
    ...mapActions("signup", ["createRegistration", "validate", "resetUserData"]),

    async submitOrder() {
      this.isProcessing = true;
      await this.createRegistration();
      this.isProcessing = false;

      if (this.errors.length === 0 && this.order) {
        this.resetUserData();
        this.$router.push("signup-thankyou");
      }
    },
  },

  computed: {
    ...mapGetters("signup", {
      total: "total",
      hasShippingAddress: "hasShippingAddress",
    }),
    ...mapState("signup", {
      customer: "customer",
      options: "options",
      errors: "errors",
      order: "order",
      shipping: "shipping"
    }),
    ...mapGetters("payment", { hasPaymentMethod: "hasPaymentMethod" }),

    canContinue() {
      return (
        this.hasShippingAddress &&
        this.shipping.cost > 0 &&
        this.hasPaymentMethod
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__notice {
    width: 95%;
  }
}

.checkout-buttons {
  display: flex;
  flex-direction: column;
  padding: 10px 35px;
  &__title {
    padding: 10px;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    white-space: nowrap;
  }
  &__button {
    margin-bottom: 15px;
    button {
      width: 100%;
    }
  }
  &__spinner {
    margin: 15px 0 25px 0;
  }
}

.button-huge {
  height: 50px !important;
  font-size: 24px !important;
}

@media only screen and (min-width: 900px) {
  .checkout-buttons {
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    width: 80%;
    text-align: center;
    &__title {
      margin-right: auto;
    }
    &__button {
      margin-left: 35px;
    }
  }
}
</style>
