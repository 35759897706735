<template>
  <div>
    <div class="signup-container">
      <FormTitle
        class="signup-container__title"
        :title="$t('signup.personalDetails')"
      />

      <div class="customer-details">
        <div>
          <span class="dark">{{ $t("signup.fullName") }}:</span>
          {{ userData.name }}
          {{ userData.surname }}
        </div>
        <div v-if="userData.company">
          <span class="dark">{{ $t("signup.company") }}:</span>
          {{ userData.company }}
        </div>
        <div>
          <span class="dark">{{ $t("signup.ssn") }}:</span> {{ maskedSsn }}
        </div>
        <div>
          <span class="dark">{{ $t("signup.dob") }}:</span> {{ userData.dob }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormTitle from "@/components/common/FormTitle.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "SignupConfirmPersonalDetails",
  components: {
    FormTitle,
  },

  methods: { ...mapActions("cart", ["setCustomerData"]) },

  computed: {
    ...mapState("signup", { userData: "userData" }),

    maskedSsn() {
      const last4 = this.userData.ssn.substring(this.userData.ssn.length - 4);
      return `*****${last4}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.strong {
  font-weight: 800;
}
.dark {
  font-weight: 600;
}
.signup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.customer-details {
  padding: 0 20px 20px 35px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .signup-container {
    max-width: 75%;
  }
}

@media only screen and (min-width: 1300px) {
  .signup-container {
    max-width: 60%;
  }
}
</style>
