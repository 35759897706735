import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import people from "@/store/modules/people";
import locale from "@/store/modules/locale";
import products from "@/store/modules/products";
import testimonials from "@/store/modules/testimonials";
import cart from "@/store/modules/cart";
import payment from "@/store/modules/payment";
import signup from "@/store/modules/signup";

const modules = {
  people,
  locale,
  products,
  testimonials,
  cart,
  payment,
  signup
};

const persistedState = createPersistedState({
  key: "vuex",
  paths: ["locale.locale", "cart", "signup"]
});

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  devtools: process.env.VUE_APP_DEVTOOLS === "true",
  modules,
  plugins: [persistedState]
});
