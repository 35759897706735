<template>
  <div>
    <Header />
    <MainNav />
    <PageTitle :title="$t('potential.businessOpportunity')" />
    <Opportunity />
    <Presentation />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import MainNav from "@/components/common/MainNav.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import Opportunity from "@/components/potential/Opportunity.vue";
import Presentation from "@/components/potential/Presentation.vue";
import user from "@/mixins/user.js";

export default {
  name: "Potential",
  mixins: [user],
  components: {
    Header,
    Footer,
    MainNav,
    PageTitle,
    Opportunity,
    Presentation,
  },
};
</script>

<style lang="scss" scoped>
.potential {
  &__title {
    padding: 20px 0 50px 0;
    font-size: 48px;
    text-transform: uppercase;
    text-align: center;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1100px) {
  .potential {
    text-align: center;
    &__container {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }
  }
}
</style>
