
import { pick, toNumber } from "lodash";

import ShopService from "@/services/Shop";

const state = () => ({
  items: [],
  user: {},
  customer: {
    name: "",
    surname: "",
    phone: "",
    mobile: "",
    email: "",
    country: "United States",
    address1: "",
    address2: "",
    city: "",
    region: "",
    postcode: ""
  },
  options: {
    pickup: false
  },
  shipping: {
    carrier: "",
    code: "",
    method: "",
    cost: 0
  },
  shippingRates: [],
  errors: [],
  order: null
});

const getters = {
  total: state => {
    if (state.items.length > 0) {
      return state.items
        .map(item => parseFloat(item.product.price) * parseInt(item.quantity))
        .reduce((total, amount) => total + amount);
    } else {
      return 0;
    }
  },

  totalTax: state => {
    if (state.items.length > 0) {
      return state.items
        .map(item => {
          const tax = item.product.tax
            ? (item.product.tax / 100) * item.product.price
            : 0;

          return tax * parseInt(item.quantity);
        })
        .reduce((total, amount) => total + amount);
    } else {
      return 0;
    }
  },

  orderTotal: (state, getters) => {
    const totalWithFee = getters.orderSubtotal + getters.creditCardFee;

    return totalWithFee;
  },

  orderSubtotal: (state, getters) => {
    return toNumber(getters.total) + toNumber(state.shipping.cost) + toNumber(getters.totalTax);
  },

  totalWeight: state => {
    if (state.items.length > 0) {
      return state.items
        .map(item => parseFloat(item.product.weight) * parseInt(item.quantity))
        .reduce((total, amount) => total + amount);
    } else {
      return 0;
    }
  },

  hasShippingAddress: state => {
    const required = pick(state.customer, [
      "name",
      "surname",
      "phone",
      "address1",
      "city",
      "region",
      "postcode"
    ]);
    for (var key in required) {
      if (required[key] === "") {
        return false;
      }
    }
    if (state.customer.postcode.length !== 5) {
      return false;
    }
    return true;
  },

  creditCardFee: (state, getters) => {    
    return getters.orderSubtotal * 0.04;
  },  
};

const actions = {
  async createOrder({ state, getters, rootState, commit }) {
    commit("setErrors", []);
    commit("setOrder", null);

    const card = rootState.payment.payment;
    const [cardMonth, cardYear] = card.expiry.split("/");

    const order = {
      user_id: state.user.user_id,
      name: state.customer.name,
      surname: state.customer.surname,
      phone: state.customer.phone,
      email: state.customer.email,
      shipping_address: state.customer.address1,
      shipping_address2: state.customer.address2,
      shipping_city: state.customer.city,
      shipping_region: state.customer.region,
      shipping_postcode: state.customer.postcode,
      shipping_country: state.customer.country,
      total_weight: getters.totalWeight,
      products: state.items.reduce((acc, item) => {
        acc.push({
          ...item.product,
          quantity: item.quantity
        });
        return acc;
      }, []),
      shipping_method: state.shipping.method,
      shipping_cost: state.shipping.cost,
      shipping_code: state.shipping.code,
      shipping_carrier: state.shipping.carrier,
      card_name: card.name,
      card_number: card.number,
      card_cvv2: card.cvc,
      card_month: cardMonth,
      card_year: cardYear,
      pickup: state.options.pickup ? 1 : 0
    };

    const result = await ShopService.createOrder(order);

    if (result.errors) {
      commit("setErrors", result.errors);
    } else if (result.order) {
      commit("setOrder", result.order);
      commit("setItems", []);
    }
  },

  async getShippingRates({ state, commit, getters }) {
    commit("setShippingRates", {});
    const params = {
      name: state.customer.name,
      surname: state.customer.surname,
      phone: state.customer.phone,
      address1: state.customer.address1,
      address2: state.customer.address2,
      city: state.customer.city,
      region: state.customer.region,
      postcode: state.customer.postcode,
      country: state.customer.country,
      total_weight: getters.totalWeight,
      products: state.items.reduce((acc, item) => {
        acc.push({
          code: item.product.code,
          name: item.product.name,
          weight: item.product.weight,
          quantity: item.quantity
        });
        return acc;
      }, [])
    };

    const rates = await ShopService.getShippingRates(params);

    commit("setShippingRates", rates);
  },

  setCustomerData({ state, commit }, { key, value }) {
    key in state.customer && commit("setCustomerData", { key, value });
  },

  setOptions({ state, commit }, { key, value }) {
    key in state.options && commit("setOptions", { key, value });
  },

  setShipping({ state, commit }, { key, value }) {
    key in state.shipping && commit("setShipping", { key, value });
  },

  setShippingRates({ commit }, rates) {
    commit("setShippingRates", rates);
  },

  setUser({ commit }, user) {
    commit("setUser", user);
  },

  addToCart({ state, commit }, { product, quantity }) {
    const cartItem = state.items.find(
      item => item.product.product_id === product.product_id
    );

    if (!cartItem) {
      commit("addToCart", { product, quantity });
    } else {
      commit("changeQuantity", { product, quantity });
    }
  },

  incrementQuantity({ state, commit }, product) {
    const item = state.items.find(
      item => item.product.product_id === product.product_id
    );
    const quantity = item.quantity + 1;
    commit("changeQuantity", { product, quantity });
  },

  removeFromCart({ state, commit }, product) {
    const filtered = state.items.filter(
      item => item.product.product_id !== product.product_id
    );
    commit("setItems", filtered);
  }
};

const mutations = {
  setCustomerData(state, { key, value }) {
    state.customer[key] = value;
  },

  setOptions(state, { key, value }) {
    state.options[key] = value;
  },

  setShipping(state, { key, value }) {
    state.shipping[key] = value;
  },

  setItems(state, items) {
    state.items = items;
  },

  setShippingRates(state, rates) {
    state.shippingRates = rates;
  },

  setUser(state, user) {
    state.user = user;
  },

  setOrder(state, order) {
    state.order = order;
  },

  setErrors(state, errors) {
    state.errors = errors;
  },

  addToCart(state, { product, quantity }) {
    return state.items.push({ product, quantity });
  },

  changeQuantity(state, { product, quantity }) {
    const index = state.items.findIndex(
      item => item.product.product_id === product.product_id
    );
    const items = [...state.items];
    items[index] = { ...items[index], quantity };

    state.items = items;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
