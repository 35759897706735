<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('signup.backofficePassword')"
      />

      <div class="form-group">
        <div class="form-group__text">
          8 or more characters, must include at least 1 number
        </div>
      </div>

      <div class="form-group">
        <div class="form-group__field">
          <v-text-field
            v-model="password"
            :label="`${$t('signup.password')} *`"
            :placeholder="$t('signup.password')"
            outlined
            dense
            type="password"
            color="#f75007"
            :rules="[rules.required]"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="password2"
            :label="`${$t('signup.confirmPassword')} *`"
            :placeholder="$t('signup.confirmPassword')"
            outlined
            type="password"
            dense
            color="#f75007"
            :rules="[rules.required]"
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/mixins/user.js";
import rules from "@/mixins/validation-rules.js";
import FormTitle from "@/components/common/FormTitle.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "FormPassword",
  mixins: [user, rules],
  components: {
    FormTitle,
  },
  methods: { ...mapActions("signup", ["setUserData"]) },

  computed: {
    ...mapState("signup", { userData: "userData" }),

    password: {
      get() {
        return this.userData.password;
      },
      set(value) {
        this.setUserData({ key: "password", value });
      },
    },

    password2: {
      get() {
        return this.userData.password2;
      },
      set(value) {
        this.setUserData({ key: "password2", value });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.form-group {
  padding: 0 20px;
}

.sponsor-details {
  padding: 0 20px 20px 35px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;

    &__field {
      width: 50%;
      padding: 5px 20px 5px 5px;
    }

    &__text {
      text-align: left;
      padding: 5px 20px 5px 5px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 70%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: start;

    &__field {
      width: 30%;
      max-width: 30%;
      padding: 5px 15px;
    }

    &__text {
      text-align: left;
      max-width: 90%;
      padding: 5px 15px;
    }
  }
}
</style>
