import { isNumber, toNumber } from "lodash";

export const formattingMixin = {
  methods: {
    formatNumber(number, decimals = 2) {
      
      const num = isNumber(number) ? number : toNumber(number);
      return num.toFixed(decimals); 
    },
    formatUSPhone(value) {
      const hasDigitsOnly = /^\d+$/.test(value);

      if (value.length === 10 && hasDigitsOnly) {
        const areaCode = value.slice(0, 3);
        const exchangeCode = value.slice(3, 6);
        const lineNumber = value.slice(6);
        return `+1 (${areaCode}) ${exchangeCode}-${lineNumber}`;
      } else {
        return value;
      }
    }    
  },
};