<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('checkout.shippingMethod')"
      />

      <div class="customer-details">
        <div>
          {{ shipping.method }} - ${{ formatNumber(shipping.cost) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormTitle from "@/components/common/FormTitle.vue";
import { formattingMixin } from "@/mixins/formatting.js";

import { mapActions, mapState } from "vuex";

export default {
  name: "ConfirmShippingMethod",
  components: {
    FormTitle,
  },

  mixins: [formattingMixin],

  methods: { ...mapActions("cart", ["setCustomerData"]) },

  computed: {
    ...mapState("cart", { shipping: "shipping" }),
  },
};
</script>

<style lang="scss" scoped>
.strong {
  font-weight: 800;
}

.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.customer-details {
  padding: 0 20px 20px 35px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 60%;
  }
}
</style>
