<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('checkout.personalDetails')"
      />

      <div class="form-group">
        <div class="form-group__field">
          <v-text-field
            v-model="name"
            :label="$t('checkout.firstName')"
            :placeholder="$t('checkout.firstName')"
            :rules="[rules.required]"
            outlined
            dense
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="surname"
            :label="$t('checkout.surname')"
            :placeholder="$t('checkout.surname')"
            :rules="[rules.required]"
            outlined
            dense
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="phone"
            :label="$t('checkout.phone')"
            :placeholder="$t('checkout.phone')"
            :rules="[rules.required]"
            outlined
            dense
            :maxlength="10"
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="mobile"
            :label="$t('checkout.mobile')"
            :placeholder="$t('checkout.mobile')"
            outlined
            dense
            type="tel"
            maxlength="10"
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="email"
            :label="$t('checkout.email')"
            :placeholder="$t('checkout.email')"
            :rules="[rules.required, rules.email]"
            outlined
            dense
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="country"
            :label="$t('checkout.country')"
            :placeholder="$t('checkout.country')"
            outlined
            dense
            readonly
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="address1"
            :label="$t('checkout.address1')"
            :placeholder="$t('checkout.address1')"
            :rules="[rules.required]"
            outlined
            dense
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="address2"
            :label="$t('checkout.address2')"
            :placeholder="$t('checkout.address2')"
            outlined
            dense
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="city"
            :label="$t('checkout.city')"
            :placeholder="$t('checkout.city')"
            :rules="[rules.required]"
            outlined
            dense
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-select
            v-model="region"
            dense
            color="#f75007"
            :items="states"
            :label="$t('checkout.region')"
            outlined
          ></v-select>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="postcode"
            :label="$t('checkout.postcode')"
            :placeholder="$t('checkout.postcode')"
            :rules="[rules.required]"
            outlined
            dense
            v-mask="'#####'"
            color="#f75007"
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormTitle from "@/components/common/FormTitle.vue";
import states from "@/data/states.js";

import { mapActions, mapState } from "vuex";

export default {
  name: "FormPersonalDetails",
  components: {
    FormTitle,
  },

  data() {
    return {
      states,
      rules: {
        required: (value) => !!value || this.$t("checkout.required"),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("checkout.invalidEmail");
        },
      },
    };
  },

  methods: {
    ...mapActions("cart", ["setCustomerData"]),
  },

  computed: {
    ...mapState("cart", { customer: "customer" }),

    name: {
      get() {
        return this.customer.name;
      },
      set(value) {
        this.setCustomerData({ key: "name", value });
      },
    },

    surname: {
      get() {
        return this.customer.surname;
      },
      set(value) {
        this.setCustomerData({ key: "surname", value });
      },
    },

    phone: {
      get() {
        return this.customer.phone;
      },
      set(value) {
        this.setCustomerData({ key: "phone", value });
      },
    },

    mobile: {
      get() {
        return this.customer.mobile;
      },
      set(value) {
        this.setCustomerData({ key: "mobile", value });
      },
    },

    email: {
      get() {
        return this.customer.email;
      },
      set(value) {
        this.setCustomerData({ key: "email", value });
      },
    },

    country: {
      get() {
        return this.customer.country;
      },
    },

    address1: {
      get() {
        return this.customer.address1;
      },
      set(value) {
        this.setCustomerData({ key: "address1", value });
      },
    },

    address2: {
      get() {
        return this.customer.address2;
      },
      set(value) {
        this.setCustomerData({ key: "address2", value });
      },
    },

    city: {
      get() {
        return this.customer.city;
      },
      set(value) {
        this.setCustomerData({ key: "city", value });
      },
    },

    region: {
      get() {
        return this.customer.region;
      },
      set(value) {
        this.setCustomerData({ key: "region", value });
      },
    },

    postcode: {
      get() {
        return this.customer.postcode;
      },
      set(value) {
        this.setCustomerData({ key: "postcode", value });
      },
    },
  },

  watch: {
    postcode(newVal) {
      this.$emit("address-change", newVal);
    },
    address1(newVal) {
      this.$emit("address-change", newVal);
    },
    address2(newVal) {
      this.$emit("address-change", newVal);
    },
    city(newVal) {
      this.$emit("address-change", newVal);
    },
    region(newVal) {
      this.$emit("address-change", newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;

  &__title {
    width: 50%;
  }
}

.form-group {
  padding: 0 20px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;

    &__field {
      width: 50%;
      padding: 5px 20px 5px 5px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 70%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: start;

    &__field {
      width: 30%;
      max-width: 30%;
      padding: 5px 15px;
    }
  }
}
</style>
