<template>
  <div class="product-properties">
    <div class="product-properties__item product-properties__item--bordered">
      <div class="product-properties__key-benefits">
        <div>{{ $t('product.keyBenefits') }}</div>
      </div>
      <div
        class="product-properties__list-item"
        v-for="(item, index) in product.properties.listItems"
        :key="index"
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          id="check"
          viewBox="0 0 1792 1896.0833"
        >
          <path
            d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z"
          ></path>
        </svg>
        <p>{{ item }}</p>
      </div>
      <div class="product-properties__disclaimer">
        {{ product.properties.disclaimer }}
      </div>
    </div>

    <div class="product-properties__item ml-9">
      <div class="product-properties__photo">
        <img
          class="products__box__image"
          :src="product.img"
          :alt="product.name"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductProperties',

  props: {
    product: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.product-properties {
  display: flex;
  flex-direction: column;
  padding: 50px 15px;
  color: #555555;
  align-items: center;
  font-size: 18px;

  &__key-benefits {
    margin-top: -40px;
    margin-bottom: 30px;
    div {
      font-size: 24px;
      padding: 0 15px;
      font-weight: bold;
      background-color: white;
      display: inline-block;
    }
  }

  &__name {
    font-size: 48px;
  }

  &__list-item {
    display: flex;

    svg {
      width: 24px;
      height: 24px;
      min-width: 24px !important;
      fill: #f75007;
      margin-top: 5px;
    }

    p {
      margin-left: 10px;
    }
  }

  &__disclaimer {
    font-size: 16px;
    padding: 0;
    margin-top: 10px;
    margin-left: -20px;
    margin-right: -20px;
  }

  &__button {
    margin-top: 15px;
  }

  &__item--bordered {
    border: 2px solid #f75007;
    padding: 20px 40px;
  }

  &__photo {
    img {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .product-properties {
    flex-direction: row;
    justify-content: center;

    &__item {
      max-width: 500px;
      min-width: 500px;
    }

    &__photo {
      padding: 30px;
    }
  }
}

@media only screen and (min-width: 1301px) {
  .product-properties {
    flex-direction: row;
    justify-content: center;

    &__item {
      max-width: 700px;
      min-width: 500px;
    }

    &__photo {
      padding: 30px;
    }
  }
}
</style>
