<template>
  <div class="person">
    <div class="person__item">
      <div class="person__photo">
        <img
          class="products__box__image"
          :src="person.img"
          :alt="person.name"
        />
      </div>
    </div>
    <div class="person__item">
      <div class="person__name">{{ person.name }}</div>
      <div class="person__title">{{ person.title }}</div>
      <div class="divider">
        <div></div>
      </div>
      <div class="person__description">{{ person.description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Person',

  props: {
    person: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.person {
  display: flex;
  flex-direction: column;
  padding: 60px 0px;
  color: #555555;
  text-align: center;
  align-items: center;

  &__name {
    font-size: 30px;
    padding-top: 15px;
  }

  &__title {
    font-size: 20px;
  }

  &__description {
    padding-top: 10px;
    text-align: justify;
    font-size: 18px;
  }

  &__item {
    max-width: 500px;
  }

  &__photo {
    img {
      max-width: 300px;
      width: 100%;
    }
  }

  .divider {
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    div {
      width: 33%;
      border-bottom: 4px solid #f75007;
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .person {
    &__photo {
      img {
        width: 100%;
      }
    }
    &__description {
      padding-top: 10px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

@media only screen and (min-width: 1301px) {
  .person {
    width: 50%;
  }
}
</style>
