<template>
  <div class="form-title">{{ title }}</div>
</template>

<script>
export default {
  name: 'FormTitle',

  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title {
  padding: 20px;
  font-size: 26px;
}
</style>
