<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('signup.membershipType')"
      />
      <v-radio-group v-model="selectedMembership">
        <div class="form-group">
        <div class="form-group__item">
          <div class="form-group__item__radio vertical-center">
            <v-radio :value="'brand_ambassador'"></v-radio>
          </div>
          <div class="form-group__item__name vertical-center">
            <span>{{ $t('signup.brandAmbassador') }}</span>
          </div>
        </div>
        <div class="form-group__item">
          <div class="form-group__item__radio vertical-center">
            <v-radio :value="'vip_customer'"></v-radio>
          </div>
          <div class="form-group__item__name vertical-center">
            <span>{{ $t('signup.vipCustomer') }}</span>
          </div>
        </div>
        </div>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import user from "@/mixins/user.js";
import FormTitle from "@/components/common/FormTitle.vue";
import { formattingMixin } from "@/mixins/formatting.js";

import { mapActions, mapState } from "vuex";

export default {
  name: "FormMembershipType",
  mixins: [user, formattingMixin],
  components: {
    FormTitle,
  },

  methods: {
    ...mapActions("signup", ["setMembershipType"]),
  },

  computed: {
    ...mapState("signup", ["membershipType"]),
    ...mapState("locale", { locale: "locale" }),

    selectedMembership: {
      get() {
        return this.membershipType;
      },
      set(value) {
        this.setMembershipType(value);
      },
    },
  }
};
</script>

<style lang="scss" scoped>
.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-group {
  padding: 0 20px;

  &__item {
    display: flex;
    padding: 5px 20px;

    &__name {
      vertical-align: middle;
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;

    &__field {
      width: 50%;
      padding: 5px 20px 5px 5px;
    }

    &__text {
      text-align: left;
      padding: 5px 20px 5px 5px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 70%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: start;

    &__field {
      width: 30%;
      max-width: 30%;
      padding: 5px 15px;
    }

    &__text {
      text-align: left;
      max-width: 90%;
      padding: 5px 15px;
    }
  }
}
</style>
