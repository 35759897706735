import Vue from "vue";
import VueRouter from "vue-router";
import Homepage from "@/pages/Homepage";
import Product from "@/pages/Product";
import People from "@/pages/People";
import Potential from "@/pages/Potential";
import Shop from "@/pages/Shop";
import ShopProductDetail from "@/pages/ShopProductDetail";
import ShoppingCart from "@/pages/ShoppingCart";
import ShopCheckout from "@/pages/ShopCheckout";
import ShopCheckoutConfirm from "@/pages/ShopCheckoutConfirm";
import ShopThankYou from "@/pages/ShopThankYou";
import SignUp from "@/pages/Signup";
import SignUpConfirm from "@/pages/SignupConfirm";
import SignupThankYou from "@/pages/SignupThankYou";
// import Maintenance from "@/pages/Maintenance";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Homepage
  },
  {
    path: "/product/:productId",
    name: "product",
    component: Product
  },
  {
    path: "/people",
    name: "people",
    component: People
  },
  {
    path: "/potential",
    name: "potential",
    component: Potential
  },
  {
    path: "/shop",
    name: "shop",
    component: Shop
  },
  {
    path: "/product-detail/:productId",
    name: "product-detail",
    component: ShopProductDetail
  },
  {
    path: "/shopping-cart",
    name: "shopping-cart",
    component: ShoppingCart
  },
  {
    path: "/checkout",
    name: "checkout",
    component: ShopCheckout
  },
  {
    path: "/checkout-confirm",
    name: "checkout-confirm",
    component: ShopCheckoutConfirm
  },
  {
    path: "/checkout-thankyou",
    name: "checkout-thankyou",
    component: ShopThankYou
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp
  },
  {
    path: "/signup-confirm",
    name: "signup-confirm",
    component: SignUpConfirm
  },
  {
    path: "/signup-thankyou",
    name: "signup-thankyou",
    component: SignupThankYou
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
