<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        v-if="options.pickup"
        class="checkout-container__title"
        :title="$t('checkout.personalDetails')"
      />

      <FormTitle
        v-if="!options.pickup"
        class="checkout-container__title"
        :title="$t('checkout.shippingAddress')"
      />

      <div class="customer-details">
        <div class="strong">{{ customer.name }} {{ customer.surname }}</div>
        <div>{{ customer.address1 }}</div>
        <div v-if="customer.address2">{{ customer.address2 }}</div>
        <div>
          {{ customer.city }}, {{ customer.region }} {{ customer.postcode }}
        </div>
        <div>{{ customer.country }}</div>
        <div>{{ formatUSPhone(customer.phone) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import FormTitle from "@/components/common/FormTitle.vue";
import { formattingMixin } from "@/mixins/formatting.js";

import { mapActions, mapState } from "vuex";

export default {
  name: "ConfirmPersonalDetails",
  components: {
    FormTitle,
  },

  mixins: [formattingMixin],

  methods: { ...mapActions("cart", ["setCustomerData"]) },

  computed: {
    ...mapState("cart", { customer: "customer", options: "options" }),
  },
};
</script>

<style lang="scss" scoped>
.strong {
  font-weight: 800;
}

.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.customer-details {
  padding: 0 20px 20px 35px;
  // border-bottom-color: rgb(221, 221, 221);
  // border-bottom-style: solid;
  // border-bottom-width: 1px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }

  .customer-details {
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 60%;
  }

  .customer-details {
  }
}
</style>
