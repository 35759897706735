<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('signup.sponsorInformation')"
      />

      <div v-if="!sponsor.sponsorId" class="form-group">
        <div class="form-group__field">
          <v-text-field
            v-model="sponsorId"
            :label="`${$t('signup.sponsorId')} *`"
            :placeholder="$t('signup.sponsorId')"
            outlined
            dense
            color="#f75007"
          ></v-text-field>
        </div>
      </div>

      <div v-if="sponsor.sponsorId" class="sponsor-details">
        <div>
          {{ sponsor.sponsorName }} ({{ sponsor.sponsorId }})
          <v-btn x-small color="#f75007" dark rounded @click="resetSponsor">{{
            $t("signup.change")
          }}</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/mixins/user.js";
import FormTitle from "@/components/common/FormTitle.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "FormSponsor",
  mixins: [user],
  components: {
    FormTitle,
  },

  created() {
    if (!this.sponsor.sponsorId && this.sponsorExists) {
      console.log("set");
    }
  },

  methods: {
    ...mapActions("signup", [
      "setSponsorData",
      "getSponsorById",
      "resetSponsor",
    ]),
  },

  computed: {
    ...mapState("signup", { sponsor: "sponsor" }),

    sponsorId: {
      get() {
        return this.sponsor.sponsorId;
      },
      set(value) {
        if (value && value.length >= 4) {
          this.getSponsorById(value);
        }
      },
    },
  },

  // watch: {
  //   sponsorId(newVal) {
  //     console.warn(newVal);
  //     console.warn(newVal.length);
  //     if (newVal && newVal.length >= 4) {
  //       console.warn("a");
  //       this.getSponsorById(newVal);
  //     }
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.form-group {
  padding: 0 20px;
}

.sponsor-details {
  padding: 0 20px 20px 35px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;

    &__field {
      width: 50%;
      padding: 5px 20px 5px 5px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 70%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: start;

    &__field {
      width: 30%;
      max-width: 30%;
      padding: 5px 15px;
    }
  }
}
</style>
