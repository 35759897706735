<template>
  <div>
    <Header />
    <MainNav />
    <ShopTitle :title="$t('cart.shoppingCartTitle')" />

    <div class="container">
      <div class="cart-header">
        <div class="cart-header__title">{{ $t("cart.shoppingCartTitle") }}</div>

        <div class="cart-header__button">
          <v-btn class="button-huge" color="#f75007" dark rounded to="/shop">
            {{ $t("cart.continueShopping") }}
          </v-btn>
        </div>

        <div class="cart-header__button" v-if="!isEmpty">
          <v-btn class="button-huge" color="" dark rounded to="checkout">
            {{ $t("cart.checkout") }}
          </v-btn>
        </div>
      </div>
    </div>

    <div v-if="isEmpty" class="cart-empty">
      <div class="cart-empty__title">{{ $t("cart.empty") }}</div>
      <img class="cart-empty__image" src="@/assets/images/cart/sad-cart.png" />
    </div>

    <div class="container" v-if="!isEmpty">
      <CartItem v-for="item in items" :item="item" :key="item.product_id" />
    </div>

    <CartTable :items="items" v-if="!isEmpty" />

    <div class="cart-footer" v-if="!isEmpty">
      <div class="cart-footer__subtotal">
        {{ $t("cart.subtotal") }}: ${{ formatNumber(total) }}
      </div>
      <div class="cart-footer__button">
        <v-btn color="" class="button-huge" dark rounded to="checkout">
          {{ $t("cart.checkout") }}
        </v-btn>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import MainNav from "@/components/common/MainNav.vue";
import ShopTitle from "@/components/shop/ShopTitle.vue";
import CartItem from "@/components/cart/CartItem.vue";
import CartTable from "@/components/cart/CartTable.vue";
import user from "@/mixins/user.js";
import { formattingMixin } from "@/mixins/formatting.js";

import { mapActions, mapState, mapGetters } from "vuex";

const cartImage = import("@/assets/images/homepage/products-bl-post-workout.jpg");

export default {
  name: "ShoppingCart",
  mixins: [user,formattingMixin],
  components: {
    Header,
    Footer,
    MainNav,
    ShopTitle,
    CartItem,
    CartTable,
  },
  data() {
    return {
      cart: {
        volume: 60,
        price: 125,
        total: 375,
        subtotal: 375,
      },
      // img: require("@/assets/images/homepage/products-bl-post-workout.jpg"),
      img: cartImage.default,
      amount: 1,
    };
  },

  methods: { ...mapActions("people", ["loadPeople"]) },

  computed: {
    ...mapGetters("cart", { total: "total" }),
    ...mapState("cart", { items: "items" }),
    ...mapState("locale", {
      locale: "locale",
    }),
    isEmpty() {
      return this.items.length > 0 ? false : true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  flex-direction: column;
}

.cart-header {
  display: flex;
  flex-direction: column;
  padding: 10px 35px;

  &__title {
    padding: 10px;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    white-space: nowrap;
  }

  &__button {
    margin-bottom: 15px;
    button {
      width: 100%;
    }
  }
}

.cart-footer {
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  justify-content: center;
  text-align: center;

  &__subtotal {
    text-align: right;
    font-size: 18px;
    font-weight: 700;
  }

  &__button {
    margin-bottom: 15px;
    margin-top: 55px;
    button {
      width: 60%;
      max-width: 332px;
    }
  }
}

.button-huge {
  height: 50px !important;
  font-size: 24px !important;
}

.cart-empty {
  padding-top: 50px;
  text-align: center;
  &__title {
    font-size: 32px;
  }
}

@media only screen and (min-width: 900px) {
  .cart-header {
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    width: 80%;
    text-align: center;
    &__title {
      margin-right: auto;
    }
    &__button {
      margin-left: 35px;
    }
  }

  .cart-body {
    display: none;
  }

  .cart-footer {
    margin-bottom: 100px;
    padding-right: 70px;
    &__subtotal {
      font-size: 22px;
    }
    &__button {
      text-align: right;
    }
  }
}
</style>
