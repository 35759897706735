<template>
  <div class="presentation">
    <iframe
      v-if="locale === 'en'"
      allowfullscreen="true"
      style="border:none;width:100%;height:526px;"
      src="https://file.brd.so/eTDqap92XrdD1SHwZRADJNHWGtDi.pdf"
    ></iframe>

    <div
      v-if="locale === 'es'"
      style="position:relative;padding-top:max(60%,326px);height:0;width:100%;margin-bottom:100px"
    >
      <iframe
        sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox"
        allowfullscreen="true"
        style="position:absolute;border:none;width:100%;height:100%;left:0;right:0;top:0;bottom:0;"
        src="https://file.brd.so/eTDqap92XrdD1SHwZRADJNHWGtDi.pdf"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Presentation",
  computed: {
    ...mapState("locale", { locale: "locale" }),
  },
};
</script>

<style lang="scss" scoped>
.presentation {
  margin-top: 100px;
  margin-bottom: 200px;
  max-width: 1070px;
  margin: 0 auto;
}
iframe {
  padding: 15px;
  margin-top: 50px;
  margin-bottom: 100px;
  display: block;
}
</style>
