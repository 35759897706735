<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('checkout.paymentMethod')"
      />

      <div class="form-group">
        <div class="form-group__field">
          <v-text-field
            v-model="name"
            :label="`${$t('checkout.cardholdersName')} *`"
            :placeholder="$t('checkout.cardholdersName')"
            outlined
            dense
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="number"
            :label="`${$t('checkout.cardNumber')} *`"
            :placeholder="$t('checkout.cardNumber')"
            outlined
            dense
            :maxlength="16"
            color="#f75007"
            v-mask="'################'"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="expiry"
            :label="`${$t('checkout.expiryDate')} *`"
            placeholder="MM/YY"
            outlined
            dense
            color="#f75007"
            v-mask="'##/##'"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="cvc"
            label="CVC *"
            placeholder="CVC"
            outlined
            dense
            :maxlength="4"
            color="#f75007"
            v-mask="'####'"
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormTitle from "@/components/common/FormTitle.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "FormPaymentMethod",
  components: {
    FormTitle,
  },

  methods: {
    ...mapActions("payment", ["setPayment"]),
  },

  computed: {
    ...mapState("payment", { payment: "payment" }),

    name: {
      get() {
        return this.payment.name;
      },
      set(value) {
        this.setPayment({ ...this.payment, ...{ name: value } });
      },
    },

    number: {
      get() {
        return this.payment.number;
      },
      set(value) {
        this.setPayment({
          ...this.payment,
          ...{ number: value },
        });
      },
    },

    expiry: {
      get() {
        return this.payment.expiry;
      },
      set(value) {
        this.setPayment({ ...this.payment, ...{ expiry: value } });
      },
    },

    cvc: {
      get() {
        return this.payment.cvc;
      },
      set(value) {
        this.setPayment({ ...this.payment, ...{ cvc: value } });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;

  &__title {
    width: 50%;
  }

  &__notice {
    padding: 0 20px;
  }
}

.form-group {
  padding: 0 20px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;

    &__field {
      width: 50%;
      padding: 5px 20px 5px 5px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 70%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: start;

    &__field {
      width: 30%;
      max-width: 30%;
      padding: 5px 15px;
    }
  }
}
</style>
