<template>
  <div class="center">
    <div class="navigation">
      <div class="navigation__item">
        <router-link to="people">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            id="ios-people"
            viewBox="0 0 448 512"
          >
            <path
              d="M317 335c11 4-11-4 0 0zm0 0c28 10 46 35 46 65H85c0-22 9-42 27-55 15-11 31-12 49-16 7-1 24-5 26-13s1-15 1-23c0-3 0-3-2-5-4-4-6-9-8-14-2-8-3-16-4-24-9 2-10-16-12-21-1-4-7-24 3-21-3-5-4-13-5-19-2-13-2-26 3-39 10-26 37-39 64-38 26 1 51 16 59 42 4 12 3 26 1 39-1 5-2 11-4 15 10-3 4 20 3 23-2 5-3 21-12 19-1 10-2 22-7 31-1 2-7 8-7 10v11c0 5 0 10 2 15s10 7 14 8c14 5 27 5 41 10zm-206-13h2-2zm0 0c-9 5-17 11-24 19-1 2-6 11-7 11H0c0-20 12-34 30-40 6-2 27-4 30-11 2-5 1-11 1-16-10 1-24-1-32-7-2-2 3-9 4-12 1-4 2-7 3-11 1-9 1-19 1-28 0-16-1-35 8-49 8-13 23-18 38-18 24 0 43 13 47 37 3 16 1 32 2 48 0 8 2 16 5 24 1 2 5 7 3 9l-6 3c-5 2-11 3-17 4-1 0-8 0-9 1s0 9 0 11c0 4 1 6 5 7 6 2 19 3 23 8 2 3 1 5-2 5-7 1-14 2-21 5 2-1 2-2-1 0-8 4-4 2-1 0zm306-10c18 6 31 20 31 40h-80c-2-8-10-14-16-19-8-6-18-13-28-15-3-1-16 1-13-5 2-4 9-5 13-6s15-2 16-7c0-1 1-14 0-14-6 0-12-1-18-2-4-1-7-2-11-4-6-3-2-6 0-11 14-32-10-88 32-105 15-6 34-5 48 3 15 9 20 27 20 44 0 20-4 44 7 62 2 3 4 4 0 6-2 1-4 1-6 2l-12 3s-9 1-13 1c0 4-2 13 1 17 6 7 21 7 29 10z"
            ></path>
          </svg>
        </router-link>
        <div class="navigation__item__text">
          <router-link to="people">
            {{ $t("homepage.people") }}
          </router-link>
        </div>
      </div>
      <div class="navigation__item">
        <div class="navigation__item--bordered">
          <router-link to="shop">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              id="beaker"
              viewBox="0 0 384 512"
            >
              <path
                d="M80 192h240v224c0 9-7 16-16 16H96c-9 0-16-7-16-16V192zm216 152v-48c0-4-4-8-8-8s-8 4-8 8v48c0 4 4 8 8 8s8-4 8-8zm-8-72c4 0 8-4 8-8s-4-8-8-8-8 4-8 8 4 8 8 8zm93-240c3 0 3 2 3 3s-2 3-3 5-13 20-13 40v336c0 35-29 64-64 64H96c-35 0-64-29-64-64V109c0-32-2-33-32-37 0-12 11-40 65-40h316zm-45 48c0-5 0-16 1-16H64c-1 0-3 0-4 1 5 7 4 32 4 44v307c0 18 14 32 32 32h210c17 0 30-14 30-32V80z"
              ></path>
            </svg>
          </router-link>

          <div class="navigation__item__text">
            <router-link to="shop">{{ $t("homepage.product") }}</router-link>
          </div>
        </div>
      </div>
      <div class="navigation__item">
        <router-link to="potential">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            id="cash"
            viewBox="0 0 512 512"
          >
            <path
              d="M0 96h512v256H0V96zm193 224c-20-23-33-58-33-96s13-73 33-96H96c0 35-29 64-64 64v80c27 0 48 21 48 48h113zm105-59c3-4 4-9 4-15 0-3 0-5-1-8s-2-6-4-8-4-4-7-6-6-4-10-5c-1 0-4-1-7-2s-5 0-8-1v-31c2 1 5 2 7 3 4 3 6 7 7 13h20c0-5-2-9-4-13s-5-8-9-11-9-5-14-6c-2-1-5-2-7-2v-9h-18v9c-2 0-4 1-6 2-5 1-10 2-14 5s-7 6-9 10-4 9-4 14c0 3 0 5 1 8s2 5 4 7 5 5 8 7 7 4 12 5c3 1 6 0 8 1v35c-3-1-6-2-9-4s-5-4-6-7-2-6-2-9h-20c0 5 2 11 4 16 3 5 6 8 10 11s9 6 15 7c3 1 5 2 8 2v9h18v-9c3 0 6-1 9-2 5-1 10-2 14-5s7-7 10-11zm182 11v-80c-35 0-64-29-64-64h-97c20 23 33 58 33 96s-13 73-33 96h113c0-27 22-48 48-48zM64 224c0-21 11-32 32-32s32 11 32 32-11 32-32 32-32-11-32-32zm320 0c0-21 11-32 32-32s32 11 32 32-11 32-32 32-32-11-32-32zm-111 11c2 1 4 2 6 4s3 5 3 9c0 2 0 4-1 6s-2 4-4 5-5 3-8 4c-1 0-2 1-4 1v-31c3 1 6 1 8 2zm-37-44c1-2 3-3 5-4s4-2 6-2v26c-4-1-7-3-9-5s-4-4-4-8c0-3 1-5 2-7zM0 416v-32h512v32H0z"
            ></path>
          </svg>
        </router-link>

        <div class="navigation__item__text">
          <router-link to="potential">
            {{ $t("homepage.potential") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/mixins/user.js";

export default {
  name: "Navigation",
  mixins: [user],
};
</script>

<style lang="scss" scoped>
.navigation {
  color: #555555;

  a {
    color: #555555;
    text-decoration: none;
  }

  display: flex;
  background-color: white;
  margin-top: -45px;
  margin-left: 25px;
  margin-right: 25px;
  z-index: 10000;
  position: relative;
  border-radius: 8px;

  &__item {
    padding: 20px;

    &__text {
      padding-bottom: 35px;
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      border-bottom-color: rgb(221, 221, 221);
      border-bottom-style: solid;
      border-bottom-width: 1px;
    }

    svg {
      fill: #f75007;
      width: 45px;
      height: 45px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .navigation {
    flex-direction: column;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1300px) {
  .navigation {
    align-items: center;
    justify-content: center;
    margin-top: -65px;
    margin-left: 100px;
    margin-right: 100px;
    max-width: 1224px;

    &__item {
      width: 100%;
      &__text {
        border-bottom: none;
        text-align: left;
        margin-top: 15px;
      }
    }

    &__item--bordered {
      padding-left: 25px;
      padding-right: 0px;
      border-right-color: rgb(221, 221, 221);
      border-right-style: solid;
      border-right-width: 1px;
      border-left-color: rgb(221, 221, 221);
      border-left-style: solid;
      border-left-width: 1px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .center {
    margin: auto;
    width: 1224px;
    padding: 10px;
  }

  .navigation {
    max-width: 1224px;
    align-items: center;
    justify-content: center;
    margin-top: -75px;
    &__item {
      flex: 0 0 33.33%;
      padding-top: 50px;
      padding-left: 30px;
      width: 100%;

      &__text {
        border-bottom: none;
        text-align: left;
        margin-top: 15px;
      }
    }
    &__item--bordered {
      padding-left: 30px;
      border-right-color: rgb(221, 221, 221);
      border-right-style: solid;
      border-right-width: 1px;
      border-left-color: rgb(221, 221, 221);
      border-left-style: solid;
      border-left-width: 1px;
    }
  }
}
</style>
