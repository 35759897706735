import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import store from "@/store";


import VueMask from "v-mask";
Vue.use(VueMask);


Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: h => h(App)
}).$mount("#app");
