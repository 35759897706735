<template>
  <nav class="nav">
    <div class="nav__logo">
      <router-link to="/">
        <img src="@/assets/images/header/bestlife-logo.png" />
      </router-link>
    </div>

    <ul class="nav__full">
      <li class="nav__item">
        <router-link to="/">{{ $t("navigation.home") }}</router-link>
      </li>
      <li class="nav__item" v-if="userExists">
        <router-link to="/shop">{{ $t("navigation.shop") }} </router-link>
      </li>
      <li class="nav__item">
        <router-link to="/signup">{{ $t("navigation.signup") }} </router-link>
      </li>
      <li class="nav__item">
        <a href="http://bestlifecentral.com/backoffice" target="_blank">{{
          $t("navigation.login")
        }}</a>
      </li>
      <li class="nav__item">
        <LanguageSelector />
      </li>
    </ul>
    <div class="nav__burger-menu" @click.stop="drawer = !drawer">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        id="bars"
        viewBox="0 0 1536 1896.0833"
      >
        <path
          d="M1536 1344v128q0 26-19 45t-45 19H64q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19H64q-26 0-45-19T0 960V832q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19H64q-26 0-45-19T0 448V320q0-26 19-45t45-19h1408q26 0 45 19t19 45z"
        ></path>
      </svg>
    </div>

    <v-navigation-drawer
      class="drawer"
      v-model="drawer"
      absolute
      temporary
      right
      v-if="drawer"
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <router-link to="/">
              <img
                class="drawer__logo"
                src="@/assets/images/header/bestlife-logo.png"
              />
            </router-link>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="drawer__item">
              <router-link to="/">{{ $t("navigation.home") }}</router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="drawer__item"
              ><router-link to="/signup"
                >{{ $t("navigation.signup") }}
              </router-link></v-list-item-title
            >
          </v-list-item>

          <v-list-item v-if="userExists">
            <v-list-item-title class="drawer__item"
              ><router-link to="/shop">{{
                $t("navigation.shop")
              }}</router-link></v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="drawer__item"
              ><router-link to="/potential">{{
                $t("navigation.businessOpportunity")
              }}</router-link></v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="drawer__item"
              ><a
                href="http://bestlifecentral.com/backoffice"
                target="_blank"
                >{{ $t("navigation.login") }}</a
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item class="mt-5">
            <LanguageSelector :mobile="true" />
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import LanguageSelector from "@/components/common/LanguageSelector.vue";
import user from "@/mixins/user.js";

export default {
  name: "MainNav",
  mixins: [user],

  data: () => ({
    drawer: false,
    group: false,
  }),

  components: {
    LanguageSelector,
  },
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer--temporary {
  z-index: 7000 !important;
}

ul {
  list-style-type: none;
}

.nav {
  &__logo {
    a {
      &:hover {
        &:after {
          width: 0 !important;
        }
      }
    }
  }

  a {
    display: inline-block;
    color: white;
    text-decoration: none;
    letter-spacing: 1px;
    font-weight: 800;

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background: white;
      transition: width 0.3s;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }

  padding: 5px 25px 5px 5px;
  text-transform: uppercase;
  background-color: #f75007;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;

  &__full {
    display: none;
  }

  &__burger-menu {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 8px;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    fill: white;
    height: 36px;
    width: 36px;
  }

  &__item {
    display: inline;
    margin: 0 15px;
    padding: 10px 0;
  }

  &__logo {
    flex-grow: 1;
    img {
      max-height: 130px;
    }
  }
}

.drawer {
  background-color: #222b34 !important;

  &__item {
    color: white !important;
  }

  &__logo {
    height: 80px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1300px) {
  .nav {
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: 20px;

    &__full {
      display: block;
      color: white;
      font-size: 14px;
    }

    &__burger-menu {
      display: none;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .nav {
    flex-direction: row;
    justify-content: space-around;

    &__full {
      display: block;
      color: white;
      font-size: 14px;
    }
    &__burger-menu {
      display: none;
    }

    &__logo {
      flex-grow: initial;
      img {
        max-height: 130px;
      }
    }
  }
}
</style>
