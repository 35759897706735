import { pick } from "lodash";

const state = () => ({
  payment: {
    number: "",
    expiry: "",
    name: "",
    ccv: ""
  }
});

const getters = {
  hasPaymentMethod: state => {
    const required = pick(state.payment, ["number", "expiry", "name", "cvc"]);

    for (var key in required) {
      if (required[key] === "") {
        return false;
      }
    }
    return true;
  }
};

const actions = {
  setPayment({ commit }, payment) {
    commit("setPayment", payment);
  }
};

const mutations = {
  setPayment(state, payment) {
    state.payment = payment;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
