<template>
  <div>
    <Header />
    <MainNav />

    <FormSponsor />
    <FormMembershipType />
    <FormPersonalDetails />
    <FormContactDetails />
    <FormBillingAddress />
    <FormShippingAddress @address-change="onAddressChange" />
    <FormSiteURL />
    <FormPassword />
    <FormProductSelection @product-change="onProductChange" />
    <div v-if="shouldShowShippingOptions">
      <FormShippingOptions />
    </div>

    <div
      v-if="hasShippingAddress && addressChanged && shippingRates.length === 0 && shouldShowShippingOptions"
      class="container"
    >
      <NoticeAddressChange
        @reload-shipping-options="reloadShippingOptions"
        :message="$t('checkout.addressChanged')"
      />
    </div>
    <FormPaymentMethod />
    <FormAgreement />
    
    <div class="container mt-9" v-if="errors.length">
      <div class="container__notice">
        <Notice :message="errors" type="error" />
      </div>
    </div>

    <div class="container mb-5">
      <div class="checkout-buttons">
        <div class="checkout-buttons__button">
          <v-btn
            class="button-huge"
            color="#f75007"
            dark
            rounded
            @click="confirm"
          >
            {{ $t("signup.continue") }}
          </v-btn>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import MainNav from "@/components/common/MainNav.vue";
import Notice from "@/components/common/Notice.vue";
import FormSponsor from "@/components/signup/FormSponsor.vue";
import FormPersonalDetails from "@/components/signup/FormPersonalDetails.vue";
import FormContactDetails from "@/components/signup/FormContactDetails.vue";
import FormBillingAddress from "@/components/signup/FormBillingAddress.vue";
import FormShippingAddress from "@/components/signup/FormShippingAddress.vue";
import FormSiteURL from "@/components/signup/FormSiteURL.vue";
import FormPassword from "@/components/signup/FormPassword.vue";
import FormProductSelection from "@/components/signup/FormProductSelection.vue";
import FormAgreement from "@/components/signup/FormAgreement.vue";
import FormShippingOptions from "@/components/signup/FormShippingOptions.vue";
import FormPaymentMethod from "@/components/checkout/FormPaymentMethod.vue";
import NoticeAddressChange from "@/components/checkout/NoticeAddressChange.vue";
import FormMembershipType from "@/components/signup/FormMembershipType.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { toNumber } from "lodash";

export default {
  name: "SignUp",
  components: {
    Header,
    MainNav,
    FormSponsor,
    FormPersonalDetails,
    FormContactDetails,
    FormBillingAddress,
    FormShippingAddress,
    FormSiteURL,
    FormPassword,
    FormProductSelection,
    FormAgreement,
    FormShippingOptions,
    FormPaymentMethod,
    Notice,
    NoticeAddressChange,
    FormMembershipType,
    Footer,
  },
  data: () => ({
    valid: true,
    error: "",
    loadingShippingOptions: false,
    addressChanged: false,
  }),

  methods: {
    ...mapActions("signup", [
      "validate",
      "setCustomerData",
      "getShippingRates",
      "setShippingRates",
      "setShipping",
    ]),

    async confirm() {
      this.error = "";

      await this.validate();

      if (this.errors.length === 0) {
        this.$router.push("/signup-confirm");
      } else {
        // if (!this.isUserDataComplete) {
        //   this.error = this.$t("signup.completeRequiredFields");
        // }
        // if (!this.isShippingMethodSelected) {
        //   this.error = this.$t("signup.completeShippingMethod");
        // }
        // if (!this.hasPaymentMethod) {
        //   this.error = this.$t("signup.completePaymentMethod");
        // }
      }
    },

    reloadShippingOptions() {
      this.addressChanged = false;
      this.getShippingRates();
    },
    onAddressChange() {
      this.addressChanged = true;
      this.setShippingRates([]);
      this.setShipping({ key: "method", value: "" });
      this.setShipping({ key: "cost", value: 0 });
    },
    onProductChange() {
      if (toNumber(this.totalWeight) > 0) {
        this.getShippingRates();
      } else {
        this.setShippingRates([]);
        this.setShipping({ key: "method", value: "" });
        this.setShipping({ key: "cost", value: 0 });
        this.setShipping({ key: "carrier", value: "" });
        this.setShipping({ key: "code", value: "" });
      }
    },
  },

  computed: {
    ...mapGetters("signup", {
      total: "total",
      hasShippingAddress: "hasShippingAddress",
      totalWeight: "totalWeight"
    }),
    ...mapState("signup", {
      product: "product",
      userData: "userData",
      shipping: "shipping",
      options: "options",
      shippingRates: "shippingRates",
      errors: "errors",
      membershipType: "membershipType",
    }),
    ...mapGetters("payment", { hasPaymentMethod: "hasPaymentMethod" }),

    shouldShowShippingOptions() {
      return toNumber(this.totalWeight) > 0;
    },

    isUserDataComplete() {
      return this.userData.name &&
        this.userData.surname &&
        this.userData.ssn &&
        this.userData.dob &&
        this.userData.siteId &&
        this.userData.password &&
        this.userData.password2 &&
        this.userData.phone &&
        this.userData.address &&
        this.userData.city &&
        this.userData.region &&
        this.userData.postcode &&
        this.userData.email
        ? true
        : false;
    },

    isShippingMethodSelected() {
      const { method, cost } = this.shipping;
      return this.options.pickup ? true : cost > 0 && method !== "";
    },

    isProductSelected() {
      return this.product ? true : false;
    },

    canContinue() {
      return (
        this.isUserDataComplete &&
        this.hasShippingAddress &&
        this.isShippingMethodSelected &&
        this.hasPaymentMethod &&
        this.isProductSelected
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__notice {
    width: 95%;
  }
}
.checkout-buttons {
  display: flex;
  flex-direction: column;
  padding: 0 35px;

  &__title {
    padding: 10px;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    white-space: nowrap;
  }
  &__button {
    margin-bottom: 15px;
    margin-top: 15px;
    button {
      width: 100%;
    }
  }
}

.button-huge {
  height: 50px !important;
  font-size: 24px !important;
}

@media only screen and (min-width: 900px) {
  .checkout-buttons {
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    width: 80%;
    text-align: center;
    &__title {
      margin-right: auto;
    }
    &__button {
      margin-left: 35px;
    }
  }
}
</style>
