<template>
  <div>
    <Header />
    <MainNav />
    <PageTitle :title="$t('checkout.thankYou')" />

    <FormTitle
      class="checkout-container__title"
      :title="$t('checkout.thankYouForOrder')"
    />
    <div class="container">
      <div>{{ $t("checkout.yourOrder") }}</div>
      <br />
      <div>{{ $t("checkout.telCustomerService") }}: 888.400.1257</div>

      <div>Fax. 832.213.2979</div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import MainNav from "@/components/common/MainNav.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import FormTitle from "@/components/common/FormTitle.vue";

import user from "@/mixins/user.js";

export default {
  name: "ShopThankYou",
  mixins: [user],
  components: {
    Header,
    Footer,
    MainNav,
    PageTitle,
    FormTitle,
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 25px;
  text-align: left;
}

.checkout-buttons {
  display: flex;
  flex-direction: column;
  padding: 10px 35px;
  &__title {
    padding: 10px;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    white-space: nowrap;
  }
}

@media only screen and (min-width: 900px) {
  .container {
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    &__title {
      margin-right: auto;
    }
  }
}
</style>
