import UserService from "@/services/User.js";
import { mapActions, mapState } from "vuex";

export default {
  async created() {
    if (!this.userExists) {
      const siteId = window.location.host.split(".")[0];
      const user = await UserService.getUserFromSiteId(siteId);

      // If no user found, set the default user to the company (1011) - siteId = steve
      if (!user) {
        const defaultUser = await UserService.getUserFromSiteId('steve');
        this.setUser(defaultUser);
      } else {
        this.setUser(user);
      }

      if (!this.sponsorExists) {
        this.setSponsorData({ key: "sponsorName", value: user.fullname });
        this.setSponsorData({ key: "sponsorId", value: user.user_id });
      }
    }
  },

  methods: {
    ...mapActions("cart", ["setUser"]),
    ...mapActions("signup", ["setSponsorData"])
  },

  computed: {
    ...mapState("cart", { user: "user" }),
    ...mapState("signup", { sponsor: "sponsor" }),

    userExists() {
      return this.user?.name ? true : false;
    },

    sponsorExists() {
      return this.sponsor?.name ? true : false;
    }
  }
};
