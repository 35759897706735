<template>
  <div class="page-title">{{ title }}</div>
</template>

<script>
export default {
  name: 'PageTitle',

  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.page-title {
  padding: 25px 100px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #12181b;
  font-size: 40px;
  text-transform: capitalize;
}
</style>
