import { render, staticRenderFns } from "./Presentation.vue?vue&type=template&id=5738563c&scoped=true&"
import script from "./Presentation.vue?vue&type=script&lang=js&"
export * from "./Presentation.vue?vue&type=script&lang=js&"
import style0 from "./Presentation.vue?vue&type=style&index=0&id=5738563c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5738563c",
  null
  
)

export default component.exports