<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('signup.billingAddress')"
      />

      <div class="form-group">
        <div class="form-group__field">
          <v-text-field
            v-model="country"
            :label="$t('signup.country')"
            :placeholder="$t('signup.country')"
            outlined
            dense
            readonly
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="address1"
            :label="`${$t('signup.address1')} *`"
            :placeholder="$t('signup.address1')"
            outlined
            dense
            color="#f75007"
            :rules="[rules.required]"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="address2"
            :label="$t('signup.address2')"
            :placeholder="$t('signup.address2')"
            outlined
            dense
            color="#f75007"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="city"
            :label="`${$t('signup.city')} *`"
            :placeholder="$t('signup.city')"
            outlined
            dense
            color="#f75007"
            :rules="[rules.required]"
          ></v-text-field>
        </div>

        <div class="form-group__field">
          <v-select
            v-model="region"
            :label="`${$t('signup.region')} *`"
            color="#f75007"
            :items="states"
            outlined
            dense
          ></v-select>
        </div>

        <div class="form-group__field">
          <v-text-field
            v-model="postcode"
            :label="`${$t('signup.postcode')} *`"
            :placeholder="$t('signup.postcode')"
            outlined
            dense
            :maxlength="5"
            color="#f75007"
            :rules="[rules.required]"
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/mixins/user.js";
import rules from "@/mixins/validation-rules.js";
import FormTitle from "@/components/common/FormTitle.vue";
import states from "@/data/states.js";

import { mapActions, mapState } from "vuex";

export default {
  name: "FormPersonalDetails",
  mixins: [user, rules],
  components: {
    FormTitle,
  },

  data() {
    return {
      states: states,
    };
  },

  methods: { ...mapActions("signup", ["setUserData"]) },

  computed: {
    ...mapState("signup", { userData: "userData" }),

    address1: {
      get() {
        return this.userData.address;
      },
      set(value) {
        this.setUserData({ key: "address", value });
      },
    },

    address2: {
      get() {
        return this.userData.address2;
      },
      set(value) {
        this.setUserData({ key: "address2", value });
      },
    },

    city: {
      get() {
        return this.userData.city;
      },
      set(value) {
        this.setUserData({ key: "city", value });
      },
    },

    region: {
      get() {
        return this.userData.region;
      },
      set(value) {
        this.setUserData({ key: "region", value });
      },
    },

    postcode: {
      get() {
        return this.userData.postcode;
      },
      set(value) {
        this.setUserData({ key: "postcode", value });
      },
    },

    country: {
      get() {
        return this.userData.country;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.form-group {
  padding: 0 20px;
}

.sponsor-details {
  padding: 0 20px 20px 35px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;

    &__field {
      width: 50%;
      padding: 5px 20px 5px 5px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 70%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: start;

    &__field {
      width: 30%;
      max-width: 30%;
      padding: 5px 15px;
    }
  }
}
</style>
