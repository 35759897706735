import { pick } from "lodash";
import UserService from "@/services/User";
import ShopService from "@/services/Shop";

const prepareSignupParams = (state, rootState, getters) => {
  const card = rootState.payment.payment;
  const [cardMonth, cardYear] = card.expiry.split("/");
  const lang = rootState.locale.locale;

  const payload = {    
    type: state.membershipType,
    lang,
    sponsor_id: state.sponsor.sponsorId,
    sponsor_name: state.sponsor.sponsorName,
    orig_sponsor_id: state.sponsor.sponsorId,
    orig_sponsor_name: state.sponsor.sponsorName,
    agree: state.options.agreedToTerms,
    name: state.userData.name,
    surname: state.userData.surname,
    company: state.userData.company,
    phone: state.userData.phone,
    mobile: state.userData.mobile,
    email: state.userData.email,
    site_id: state.userData.siteId,
    password: state.userData.password,
    password2: state.userData.password2,
    address: state.userData.address,
    address2: state.userData.address2,
    city: state.userData.city,
    region: state.userData.region,
    postcode: state.userData.postcode,
    country: state.userData.country,
    shipping_name: state.userData.name,
    shipping_surname: state.userData.surname,
    shipping_phone: state.userData.phone,
    shipping_address: state.userData.shippingAddress,
    shipping_address2: state.userData.shippingAddress2,
    shipping_city: state.userData.shippingCity,
    shipping_region: state.userData.shippingRegion,
    shipping_postcode: state.userData.shippingPostcode,
    shipping_country: state.userData.shippingCountry,
    order_items: {
      [state.product.product_id]: { ...state.product, quantity: 1 }
    },
    total_weight: getters.totalWeight,
    shipping_method: state.shipping.method,
    shipping_cost: state.shipping.cost,
    shipping_code: state.shipping.code,
    shipping_carrier: state.shipping.carrier,
    card_name: card.name,
    card_number: card.number,
    card_cvv2: card.cvc,
    card_month: cardMonth,
    card_year: cardYear,
    pickup: state.options.pickup ? 1 : 0    
  };

  return payload;
};

const state = () => ({
  membershipType: "brand_ambassador",
  userData: {
    name: "",
    surname: "",
    company: "",
    ssn: "",
    dob: "",
    phone: "",
    mobile: "",
    email: "",
    address: "",
    address2: "",
    city: "",
    region: "",
    postcode: "",
    country: "United States",
    shippingAddress: "",
    shippingAddress2: "",
    shippingCity: "",
    shippingRegion: "",
    shippingPostcode: "",
    shippingCountry: "United States",
    siteId: "",
    password: "",
    password2: ""
  },
  sponsor: {
    sponsorId: null,
    sponsorName: ""
  },
  options: {
    sameShippingAddress: false,
    agreedToTerms: false,
    pickup: false
  },
  product: null,
  shipping: {
    carrier: "",
    code: "",
    method: "",
    cost: 0
  },
  shippingRates: [],
  errors: [],
  order: null,
  newUser: null
});

const getters = {
  total: state => {
    if (state.product) {
      return parseFloat(state.product.price);
    } else {
      return 0;
    }
  },

  totalTax: state => {
    if (state.product) {
      const tax = state.product.tax
        ? (state.product.tax / 100) * parseFloat(state.product.price)
        : 0;

      return tax;
    } else {
      return 0;
    }
  },

  orderTotal: (state, getters) => {
    return getters.total + state.shipping.cost + getters.totalTax;
  },

  totalWeight: state => {
    if (state.product) {
      return state.product.weight;
    } else {
      return 0;
    }
  },

  hasShippingAddress: state => {
    const required = pick(state.userData, [
      "name",
      "surname",
      "phone",
      "shippingAddress",
      "shippingCity",
      "shippingRegion",
      "shippingPostcode"
    ]);

    for (var key in required) {
      if (required[key] === "") {
        return false;
      }
    }
    if (state.userData.shippingPostcode.length !== 5) {
      return false;
    }
    return true;
  }
};

const actions = {
  async createRegistration({ state, getters, rootState, commit }) {
    commit("setErrors", []);
    commit("setOrder", null);

    const payload = prepareSignupParams(state, rootState, getters);

    const result = await UserService.register(payload);

    if (result.errors.length > 0) {
      commit("setErrors", result.errors);
    } else if (result.order) {
      commit("setOrder", result.order);
      commit("setNewUser", result.user);
    }
  },

  async validate({ state, getters, rootState, commit }) {
    commit("setErrors", []);

    const payload = prepareSignupParams(state, rootState, getters);

    const result = await UserService.validateSignup(payload);
    const { isValid } = result;
    if (!isValid) {
      commit("setErrors", result.errors);
    }
  },

  resetSponsor({ commit }) {
    commit("setSponsorData", { key: "sponsorId", value: null });
    commit("setSponsorData", { key: "sponsorName", value: "" });
  },

  async getSponsorById({ state, commit }, id) {
    const sponsor = await UserService.getSponsorById(id);
    if (sponsor) {
      commit("setSponsorData", { key: "sponsorId", value: sponsor.user_id });
      commit("setSponsorData", { key: "sponsorName", value: sponsor.fullname });
    } else if (state.sponsor.sponsorId) {
      this.resetSponsor();
    }
  },

  resetUserData({ state, commit }) {
    Object.keys(state.userData).forEach(key => {
      // Country field is read only. Do not reset
      if (key === "country" || key === "shippingCountry") {
        return;
      }
      commit("setUserData", { key, value: '' })
    })
  },

  setUserData({ state, commit }, { key, value }) {
    key in state.userData && commit("setUserData", { key, value });
  },
  setSponsorData({ state, commit }, { key, value }) {
    key in state.sponsor && commit("setSponsorData", { key, value });
  },
  setOptions({ state, commit }, { key, value }) {
    key in state.options && commit("setOptions", { key, value });
  },
  setProduct({ commit }, product) {
    commit("setProduct", product);
  },

  async getShippingRates({ state, commit, getters }) {
    commit("setShippingRates", {});
    const params = {
      name: state.userData.name,
      surname: state.userData.surname,
      phone: state.userData.phone,
      address1: state.userData.shippingAddress,
      address2: state.userData.shippingAddress2,
      city: state.userData.shippingCity,
      region: state.userData.shippingRegion,
      postcode: state.userData.shippingPostcode,
      country: state.userData.shippingCountry,
      total_weight: getters.totalWeight,
      products: [
        {
          code: state.product.code,
          name: state.product.name,
          weight: state.product.weight,
          quantity: 1
        }
      ]
    };

    const rates = await ShopService.getShippingRates(params);

    commit("setShippingRates", rates);
  },

  setShipping({ state, commit }, { key, value }) {
    key in state.shipping && commit("setShipping", { key, value });
  },

  setShippingRates({ commit }, rates) {
    commit("setShippingRates", rates);
  },

  setMembershipType({ commit }, type) {
    commit("setMembershipType", type);
  }
};

const mutations = {
  setUserData(state, { key, value }) {
    state.userData[key] = value;
  },
  setSponsorData(state, { key, value }) {
    state.sponsor[key] = value;
  },
  setOptions(state, { key, value }) {
    state.options[key] = value;
  },
  setProduct(state, product) {
    state.product = product;
  },
  setShippingRates(state, rates) {
    state.shippingRates = rates;
  },
  setShipping(state, { key, value }) {
    state.shipping[key] = value;
  },
  setOrder(state, order) {
    state.order = order;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setNewUser(state, user) {
    state.newUser = user;
  },
  setMembershipType(state, type) {
    state.membershipType = type;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
